import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { VNode } from "vue";
import { PointClickEvent } from "./HistoryMap";
import TimelineElement from "@/api/models/Timeline";
import LocationElement from "@/components/Timeline/LocationElement";
import EventElement from "@/components/Timeline/EventElement";
import ChannelsElement from "@/components/Timeline/ChannelsElement";

@Component({
    components: {
        LocationElement,
        EventElement,
        ChannelsElement
    }
})
export default class TimelineItem extends Vue {
    private onLocationClick(event: PointClickEvent) {
        this.$emit("locationClick", event);
    }

    @Prop()
    private timestamp!: string;

    // @Watch("timestamp")
    // onTimestampChanged() {
    //     if (dayjs(this.timestamp).isSame(dayjs(this.timelineItem.Timestamp))) {
    //         this.$el.scrollIntoView();
    //     }
    // }

    private get isCurrent() {
        const isCurrent = this.timestamp === this.timelineElement.Timestamp;
        if (isCurrent) {
            this.$el.scrollIntoView(true);
        }
        return isCurrent;
    }

    @Prop()
    private timelineElement!: TimelineElement;

    public render(): VNode {
        return (
            <div class="TimelineItem">
                <div class="TimelineHead" />
                <div class="TimelineItemTail" />
                <div class="TimelineContent">
                    <div class="mb-1 font-weight-medium">
                        <date-wrapper class="mr-3" date={this.timelineElement.Timestamp} format="DD.MM.YY HH:mm:ss" />
                        {this.isCurrent && <el-tag size="mini">Current</el-tag>}
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-column mb-3">
                            {this.timelineElement.LocationData.map(locationData => (
                                <location-element
                                    onLocationClick={this.onLocationClick}
                                    locationData={locationData}
                                    timestamp={this.timelineElement.Timestamp}
                                />
                            ))}
                        </div>
                        <div class="d-flex flex-wrap">
                            {this.timelineElement.StatusData.map(eventData => (
                                <event-element eventData={eventData} />
                            ))}
                        </div>
                        <channels-element channelsData={this.timelineElement.ChannelData} />
                    </div>
                </div>
            </div>
        );
    }
}
