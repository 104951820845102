import { Component, Vue, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import BeaconOption from "@/components/BeaconOption";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import { State } from "vuex-class";
import getPeriods from "@/const/datepicker-options";
import "./BnwasReportPanel.scss";

export interface BnwasReportForm {
    ids: number[];
    datetimerange: any;
    minSpeed: number;
    minDistance: number;
}

@Component({
    components: {
        BeaconOption
    }
})
export default class BnwasReportPanel extends Vue {
    @Prop()
    loading!: boolean;

    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    private async onSubmitForm() {
        try {
            const form: any = this.$refs.form;
            await form.validate();
            this.$emit("submitForm", this.form);
        } catch (error) {}
    }

    private form: BnwasReportForm = {
        ids: [],
        datetimerange: null,
        minDistance: 0,
        minSpeed: 0
    };

    private pickerOptions: any = {};

    public mounted() {
        this.pickerOptions = getPeriods(this.$t);

        const start = new Date();
        const end = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
        this.form.datetimerange = [start, end];
    }

    private handleSelectionChange(val: IBeaconMonitoring[]) {
        this.form.ids = val.map(val => val.Id);
    }

    private handleRowClick(row) {
        const table = this.$refs.table as any;
        table.toggleRowSelection(row);
    }

    public render(): VNode {
        return (
            <div class="BnwasReportPanel">
                <el-form attrs={{ model: this.form }} show-message={false} ref="form" size="small">
                    <div class="font-weight-medium text-left w-100 mb-2">{this.$t("default.beacons")}</div>
                    <el-form-item>
                        <el-table
                            data={this.beacons}
                            empty-text={this.$t("default.nodata")}
                            on-selection-change={this.handleSelectionChange}
                            on-row-click={this.handleRowClick}
                            height="300px"
                            ref="table"
                            row-key="Id"
                            size="mini"
                        >
                            <el-table-column reserve-selection={true} type="selection" width="55" />
                            <el-table-column label={this.$t("default.selectAll")} property="Name" />
                        </el-table>
                    </el-form-item>
                    <div class="font-weight-medium text-left w-100 mb-2">{this.$t("default.minimumSpeedKnots")}</div>
                    <el-form-item>
                        <el-input-number
                            vModel={this.form.minSpeed}
                            precision={2}
                            step={0.1}
                            min={0}
                            max={10}
                        ></el-input-number>
                    </el-form-item>
                    <div class="font-weight-medium text-left w-100 mb-2">{this.$t("default.minimumDistanceM")}</div>
                    <el-form-item>
                        <el-input-number vModel={this.form.minDistance} step={100} min={0} max={1000}></el-input-number>
                    </el-form-item>
                    <div class="font-weight-medium text-left w-100 mb-2">{this.$t("default.interval")}</div>
                    <el-form-item rules={[{ required: true }]} prop="datetimerange">
                        <el-date-picker
                            class="w-100"
                            vModel={this.form.datetimerange}
                            clearable={false}
                            size="small"
                            format="yyyy-MM-dd HH:mm"
                            type="datetimerange"
                            picker-options={this.pickerOptions}
                            range-separator="-"
                            unlink-panels
                            start-placeholder={this.$t("datepicker.from")}
                            end-placeholder={this.$t("datepicker.to")}
                            align="left"
                        />
                    </el-form-item>
                </el-form>
                <el-button loading={this.loading} onClick={this.onSubmitForm} size="small" type="primary">
                    {this.$t("default.build")}
                </el-button>
            </div>
        );
    }
}
