import "./BeaconBattery.scss";

export default ({ props }) => {
    let levels: any = [];
    for (let index = 0; index < Math.round(props.batteryCharge / 20); index++) {
        levels.push(<div key={index} class="battery__level" />);
    }

    return (
        props.batteryCharge && (
            <div class="d-flex align-items-center h-100 battery">
                <div class="battery__indicator">{levels}</div>
                <span class={["nopointer font-size-xs", props.innerClass]}>
                    {`${Math.round(props.batteryCharge)}%`}
                </span>
            </div>
        )
    );
};
