/* eslint-disable no-undef */
import * as utils from "./utils";
import Vue from "vue";

export default Vue.extend({
    name: "YMap",
    render(h) {
        return h();
    },
    props: {
        mapLink: String
    },
    data() {
        return {
            ymapEventBus: utils.emitter
        };
    },
    methods: {
        init() {
            this.$emit("scriptIsLoaded");
        }
    },
    mounted() {
        if (this.ymapEventBus.scriptIsNotAttached) {
            const yandexMapScript = document.createElement("SCRIPT");
            let mapLink =
                "https://api-maps.yandex.ru/2.1/?apikey=a32c65bc-aed1-4438-87bf-f957063933b1&lang=ru_RU";
            if (localStorage.getItem("locale") === "en") {
                mapLink =
                    "https://api-maps.yandex.ru/2.1/?apikey=a32c65bc-aed1-4438-87bf-f957063933b1&lang=en_US";
            } else {
                mapLink =
                    "https://api-maps.yandex.ru/2.1/?apikey=a32c65bc-aed1-4438-87bf-f957063933b1&lang=ru_RU";
            }
            yandexMapScript.setAttribute("src", mapLink);
            yandexMapScript.setAttribute("async", "");
            yandexMapScript.setAttribute("defer", "");
            document.body.appendChild(yandexMapScript);
            this.ymapEventBus.scriptIsNotAttached = false;
            yandexMapScript.onload = () => {
                this.ymapEventBus.ymapReady = true;
                this.ymapEventBus.$emit("scriptIsLoaded");
            };
        }
        if (this.ymapEventBus.ymapReady) {
            ymaps.ready(this.init);
        } else {
            this.ymapEventBus.$on("scriptIsLoaded", () => {
                this.ymapEventBus.initMap = () => {
                    // this.myMap.destroy()
                    this.init();
                };
                ymaps.ready(this.init);
            });
        }
    }
});
