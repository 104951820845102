//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "PaymentSuccessView",
    methods: {
        goHome() {
            this.$router.push("/");
        },
        goProfile() {
            this.$router.push("/profile");
        }
    }
};
