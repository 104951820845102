import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import { LocationData } from "@/api/models/Timeline";
import "./LocationElement.scss";

@Component
export default class LocationElement extends Vue {
    @Prop()
    private locationData!: LocationData;

    @Prop()
    private timestamp!: string;

    private coordintesFormat: string = "ddd";

    private onLocationClick() {
        this.$emit("locationClick", { Timestamp: this.timestamp, ...this.locationData });
    }

    public render(): VNode {
        return (
            <el-button type="text" class="ml-0" onClick={this.onLocationClick}>
                <div class="LocationElement">
                    <i class="fad fa-lg fa-map-marker-alt mr-3" />
                    <coordinates-wrapper
                        coordinates={[this.locationData.Latitude, this.locationData.Longitude]}
                        format={this.coordintesFormat}
                    />
                </div>
            </el-button>
        );
    }
}
