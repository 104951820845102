import TrackCard from "./TrackCard";
import ColorPicker from "@/components/Select/ColorPicker.vue";

import getPeriods from "@/const/datepicker-options";

import colors from "@/const/colors";

import { DELETE_TRACK } from "@/store/const/mutation-types";

import { Component, Vue } from "vue-property-decorator";
import { State, Action, Mutation } from "vuex-class";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import IconBase from "@/components/Icons/IconBase.vue";
import { TrackSettings } from "../Map/yandex/Track";

@Component({
    components: {
        IconBase,
        TrackCard,
        ColorPicker
    }
})
export default class TracksPanel extends Vue {
    render() {
        const beaconsSelect = this.beacons.map(beacon => (
            <el-option key={beacon.Id} label={beacon.Name} value={beacon.Id}>
                <div class="d-flex align-items-center">
                    <div
                        style={{
                            backgroundColor: `${beacon.UserInterfaceSettings.Color}22`
                        }}
                        class="rounded-circle mr-2"
                    >
                        <icon-base
                            iconHeight={24}
                            iconWidth={24}
                            iconName={beacon.IconPath}
                            iconColor={beacon.UserInterfaceSettings.Color}
                        />
                    </div>
                    <div>{beacon.Name}</div>
                </div>
            </el-option>
        ));

        let thicknessSelect: any[] = [];
        for (let index = 1; index < 6; index++) {
            thicknessSelect.push(<el-option key={index} label={index + "px"} value={index} />);
        }

        let trackCards: any = null;

        if (this.tracks.length === 0) {
            trackCards = <span class="text-muted p-4">{this.$t("tracks.notracks")}</span>;
        } else {
            const cards = this.tracks.map(track => (
                <track-card key={track.Id} track={track} onDeleteTrack={this.handleDeleteTrack} />
            ));
            trackCards = cards;
        }
        return (
            <div class="d-flex flex-column h-100" style={this.style}>
                <el-form
                    ref="trackForm"
                    attrs={{ model: this.form }}
                    show-message={false}
                    class="p-3 panel-header-overlay"
                >
                    <el-form-item
                        class="lo-form-item"
                        label={this.$t("default.beacon")}
                        prop="beacon"
                        rules={[
                            {
                                required: true,
                                message: this.$t("tracks.beaconvalidation"),
                                trigger: "change"
                            }
                        ]}
                    >
                        <el-select vModel={this.form.beacon} filterable size="small">
                            {beaconsSelect}
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        class="lo-form-item"
                        label={this.$t("default.interval")}
                        prop="period"
                        rules={[
                            {
                                required: true,
                                message: this.$t("tracks.periodvalidation"),
                                trigger: "change"
                            }
                        ]}
                    >
                        <el-date-picker
                            class="w-100"
                            vModel={this.form.period}
                            clearable={false}
                            size="small"
                            format="yyyy-MM-dd HH:mm"
                            type="datetimerange"
                            picker-options={getPeriods(this.$t)}
                            range-separator="-"
                            unlink-panels
                            start-placeholder={this.$t("datepicker.from")}
                            end-placeholder={this.$t("datepicker.to")}
                            align="left"
                        />
                    </el-form-item>
                    <el-form-item class="lo-form-item" label={this.$t("default.color")} prop="color">
                        <color-picker items={colors} vModel={this.form.color} />
                    </el-form-item>
                    <el-form-item class="lo-form-item" label={this.$t("tracks.linewidth")} prop="trackLineWidth">
                        <el-select vModel={this.form.trackLineWidth} size="small" placeholder="Select">
                            {thicknessSelect}
                        </el-select>
                    </el-form-item>
                    <el-form-item class="lo-form-item">
                        <div class="d-flex justify-content-center mt-1">
                            <el-button
                                size="small"
                                class="w-100"
                                type="primary"
                                loading={this.loading}
                                onClick={this.handleShowTrack}
                            >
                                {this.$t("tracks.draw")}
                            </el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <div class="d-flex flex-column p-2 scroll">{trackCards}</div>
            </div>
        );
    }

    id: number = 0;
    form: any = {
        beacon: null,
        period: null,
        trackLineWidth: 3,
        color: colors[0]
    };
    loading: boolean = false;

    @State("tracks", { namespace: "tracks" })
    tracks;

    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    get visibleTracksIds() {
        return this.tracks.filter(el => !el.Hidden).map(el => el.Id);
    }

    get style() {
        return {
            maxHeight: this.$store.state.canvasSize.h - 30 + "px"
        };
    }

    @Action("addTrack", { namespace: "tracks" })
    addTrack;

    @Mutation(DELETE_TRACK, { namespace: "tracks" })
    deleteTrack;

    async handleShowTrack() {
        try {
            let form: any = this.$refs["trackForm"];
            await form.validate();

            this.id = this.id + 1;
            let beacon = this.beacons.find(el => el.Id === this.form.beacon);
            if (!beacon) {
                return;
            }
            let trackSettings: TrackSettings = {
                Id: this.id,
                BeaconId: beacon.Id,
                StartDt: this.form.period[0],
                EndDt: this.form.period[1],
                IconPath: beacon.IconPath,
                LastCoordinateDt: beacon.Location ? beacon.Location.Timestamp : undefined,
                BeaconName: beacon.Name,
                Color: this.form.color,
                TrackLineWidth: this.form.trackLineWidth
            };

            this.loading = true;

            let dataExist = await this.addTrack(trackSettings);
            if (!dataExist) {
                this.$alert(this.$t("tracks.nodata").toString(), this.$t("default.nodata").toString(), {
                    showClose: false,
                    type: "warning",
                    confirmButtonText: "ОК"
                });
            }
            this.nextColor();
        } catch (error) {
        } finally {
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    }

    nextColor() {
        let id = (colors.findIndex(el => el === this.form.color) + 1) % colors.length;
        this.form.color = colors[id];
    }

    handleDeleteTrack(track) {
        this.deleteTrack(track);
    }

    mounted() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
        this.form.period = [start, end];
    }
}
