import { Vue, Component } from "vue-property-decorator";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";
import { initPayment } from "@/api/account";
import Panel from "@/components/Containers/Panel";
import { getConfig } from "@/api/http";
const styles = require("./PaymentView.module.scss");

@Component({
    components: {
        Panel
    }
})
export default class PaymentView extends Vue {
    working: boolean = false;
    paymentInfo = {
        sum: 50.0,
        successUrl: "/#/payment/success",
        failUrl: "/#/payment/error"
    };

    @State
    user!: IUser;

    async mounted() {
        const config = await getConfig();
        this.paymentInfo = { ...this.paymentInfo, ...{
            successUrl: config.appUrl + this.paymentInfo,
            failUrl: config.appUrl + this.paymentInfo
        } };
    }

    async pay() {
        try {
            await (this.$refs["paymentform"] as any).validate();

            this.working = true;
            let { data } = (await initPayment(this.paymentInfo)) as any;
            window.location.replace(data);
        } catch (error) {
        } finally {
            setTimeout(() => {
                this.working = false;
            }, 1000);
        }
    }

    rules() {
        return {
            sum: [
                {
                    required: true,
                    trigger: "blur",
                    message: this.$t("payment.sumToPayRequired")
                }
            ]
        };
    }

    render() {
        return (
            <div class="page-view scroll canvas">
                <div class="container-fluid p-3">
                    <div class="row m-0 justify-content-md-center">
                        <div class="p-0 col-md-12 col-lg-6 col-xl-4">
                            <panel header={this.$t("payment.fillBalance")}>
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    {this.user && (
                                        <div class={styles.paymentViewBalance}>
                                            {this.$t("payment.balance", { balance: this.user.Balance })}
                                        </div>
                                    )}
                                    <el-form
                                        ref="paymentform"
                                        rules={this.rules()}
                                        {...{ attrs: { model: this.paymentInfo } }}
                                    >
                                        <el-form-item label={this.$t("payment.sumToPay")} prop="sum">
                                            <el-input-number
                                                class="w-100"
                                                controls={false}
                                                vModel={this.paymentInfo.sum}
                                                min={5}
                                                step={1}
                                                precision={0}
                                                max={15000}
                                            />
                                        </el-form-item>
                                    </el-form>
                                    <div class="d-flex justify-content-center">
                                        <el-button
                                            size="small"
                                            loading={this.working}
                                            onClick={this.pay}
                                            type="primary"
                                        >
                                            {this.$t("payment.fill")}
                                        </el-button>
                                    </div>
                                </div>
                            </panel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
