//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ErrorView",

    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        error() {
            if (this.$store.state.error) {
                return this.$store.state.error;
            } else {
                this.goHome();
            }
        }
    },

    methods: {
        goHome() {
            window.location.href = "/";
        }
    }
};
