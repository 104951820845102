import loIntersection from "lodash/intersection";
import loIntersectionBy from "lodash/intersectionBy";
import { findBeacons } from "@/search";

import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";

import "./BeaconsGroup.scss";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";

@Component
export default class BeaconsGroup extends Vue {
    private isOpened: boolean = false;

    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    @State
    user!: IUser;

    @Prop()
    group;

    @Prop()
    search;

    @Prop()
    sort;

    @Prop()
    isList;

    get beaconsWithLocationData() {
        return this.filteredBeacons.filter(el => el.Location);
    }

    get beaconsWithTrackData() {
        return this.filteredBeacons.filter(el => el.HasLocationData);
    }

    get groupBeacons() {
        if (this.group.BeaconIds) {
            return this.sort(this.beacons.filter(el => this.group.BeaconIds.includes(el.Id)));
        }
        return [];
    }

    get filteredBeacons() {
        if (this.search) {
            return findBeacons(this.groupBeacons, this.search);
        }
        return this.groupBeacons;
    }

    get isCheckedPosition() {
        return (
            this.filteredBeacons &&
            this.filteredBeacons.length > 0 &&
            loIntersection(this.beaconsWithLocationData.map(el => el.Id), this.$store.state.monitoring.beaconsPosition)
                .length === this.beaconsWithLocationData.length
        );
    }

    get isCheckedTrack() {
        return (
            this.filteredBeacons &&
            this.filteredBeacons.length > 0 &&
            loIntersectionBy(this.beaconsWithTrackData, this.$store.state.monitoring.beaconsTrackData, "Id").length ===
                this.beaconsWithTrackData.length
        );
    }

    toggleGroup() {
        if (!this.isList) {
            if (!this.isOpened) {
                let groups = JSON.parse(localStorage.getItem("monitoring:openedgroups") as string);
                if (!Array.isArray(groups)) {
                    groups = [];
                }
                groups.push(this.group.Id);
                localStorage.setItem("monitoring:openedgroups", JSON.stringify([...new Set(groups)]));
            } else {
                let groups = JSON.parse(localStorage.getItem("monitoring:openedgroups") as string);
                if (Array.isArray(groups)) {
                    groups = groups.filter(groupId => groupId !== this.group.Id);
                }
                localStorage.setItem("monitoring:openedgroups", JSON.stringify([...new Set(groups)]));
            }
            this.isOpened = !this.isOpened;
        }
    }

    togglePosition(value) {
        this.$emit("togglePosition", this.beaconsWithLocationData, value);
    }

    toggleTrack(value) {
        this.$emit("toggleTrack", this.beaconsWithTrackData, value);
    }

    created() {
        let groups = JSON.parse(localStorage.getItem("monitoring:openedgroups") as string);
        if (Array.isArray(groups) && groups.includes(this.group.Id)) {
            this.isOpened = true;
        }
    }

    render() {
        if (!this.user) {
            return null;
        }

        return (
            <div class="lo-beacons-group">
                <div class="lo-beacons-group__card">
                    <div class="lo-beacons-group__info" onClick={this.toggleGroup}>
                        {!this.isList && this.filteredBeacons.length > 0 ? (
                            <div
                                class={[
                                    "group-arrow flex-shrink-0 fas fa-angle-right",
                                    this.isOpened && "group-arrow--opened"
                                ]}
                            />
                        ) : (
                            <div class="group-arrow" />
                        )}

                        <div
                            class={[
                                "group-name",
                                this.user.MonitoringInterfaceSettings.CardSize === "small" && "group-name_small"
                            ]}
                        >
                            <div class="text-truncate mr-1 text-left">
                                {this.group.Name === null ? this.$t("enums.groups.nogroup") : this.group.Name}
                            </div>
                            {this.filteredBeacons.length !== this.groupBeacons.length ? (
                                <div class="mr-1">
                                    ({this.filteredBeacons.length}/{this.groupBeacons.length})
                                </div>
                            ) : (
                                <div class="mr-1">({this.groupBeacons.length})</div>
                            )}
                        </div>
                    </div>
                    {/* <transition name="el-fade-in"> */}
                    {(this.isOpened || this.isList) && this.filteredBeacons.length > 0 ? (
                        <div class="d-flex" style="padding-right: 4px; flex: 0 0 auto;">
                            <el-checkbox-button
                                type="primary"
                                class={[
                                    "lo-card-button lo-beacons-group__button fa-lg fas fa-crosshairs mr-2",
                                    this.user.MonitoringInterfaceSettings.CardSize === "small" &&
                                        "lo-beacons-group__button_small"
                                ]}
                                value={this.isCheckedPosition}
                                disabled={this.beaconsWithLocationData.length === 0}
                                onChange={this.togglePosition}
                            />
                            <el-checkbox-button
                                type="primary"
                                class={[
                                    "lo-card-button lo-beacons-group__button fa-lg fas fa-route mr-2",
                                    this.user.MonitoringInterfaceSettings.CardSize === "small" &&
                                        "lo-beacons-group__button_small"
                                ]}
                                value={this.isCheckedTrack}
                                disabled={this.beaconsWithTrackData.length === 0}
                                onChange={this.toggleTrack}
                            />
                            <el-dropdown trigger="click">
                                <el-button
                                    disabled
                                    class={[
                                        "lo-card-button lo-beacons-group__button fa-lg fas fa-ellipsis-v",
                                        this.user.MonitoringInterfaceSettings.CardSize === "small"
                                            ? "lo-beacons-group__button_small mr-0"
                                            : " mr-1"
                                    ]}
                                />
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item class="beacon-card__command" command="settings">
                                        Настройки
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    ) : null}
                    {/* </transition> */}
                </div>
                {/* <el-collapse-transition> */}
                <div vShow={this.isOpened || this.isList}>
                    {this.$scopedSlots.default &&
                        this.$scopedSlots.default({
                            filteredBeacons: this.filteredBeacons
                        })}
                </div>
                {/* </el-collapse-transition> */}
            </div>
        );
    }
}
