/* global ymaps */
import templates from "@/components/Map/yandex/templates";
import { geometryTypes } from "@/const/enums";
import YandexMap from "@/components/Map/yandex/YandexMap";
import IGeometry from "@/contracts/IGeometry";
import IGeoobject from "@/contracts/IGeoobject";

export default class Geoobject {
    public map: YandexMap;
    public geoobject: IGeoobject;
    public layer: ymaps.GeoObject;

    constructor(map: YandexMap, geoobject: IGeoobject) {
        this.map = map;
        this.geoobject = geoobject;
        let geometry;
        if (this.isGeometryValid(this.geoobject)) {
            geometry = this.geoobject.Geometry;
        } else {
            const geometryType = geometryTypes.find(
                x => x.value === geoobject.GeoobjectType
            );
            if (geometryType) {
                geometry = this.getDefaultGeometry(this.map, geometryType.key);
            }
        }

        this.layer = this.createGeoobject(geometry, {
            Name: geoobject.Name,
            Color: geoobject.InterfaceSettings.color,
            LineWidth: geoobject.InterfaceSettings.lineWidth
        });
    }

    public getBounds(): ymaps.CoordinateBounds | null {
        if (this.layer.geometry) {
            return this.layer.geometry.getBounds();
        }
        return null;
    }

    public startEditing(updateGeometryCallback) {
        this.layer.editor.startEditing();
        updateGeometryCallback();
        if (this.layer.geometry) {
            this.layer.geometry.events.add("change", updateGeometryCallback);
        }
    }

    public stopEditing(updateGeometryCallback) {
        this.layer.editor.stopEditing();
        if (this.layer.geometry) {
            this.layer.geometry.events.remove("change", updateGeometryCallback);
        }
    }

    public focus() {
        if (this.map.isLayerOnMap(this.layer) && this.layer.geometry) {
            const geometryType = this.layer.geometry.getType();
            if (geometryType === "Point") {
                const center = (this.layer
                    .geometry as ymaps.geometry.Point).getCoordinates();
                this.map.setCenter(center);
            } else if (geometryType === "Polygon") {
                const bounds = this.layer.geometry.getBounds();
                this.map.setBounds(bounds);
            }
        }
    }

    public show() {
        if (!this.map.isLayerOnMap(this.layer)) {
            this.map.addLayer(this.layer);
        }
    }

    public hide() {
        this.map.removeLayer(this.layer);
    }

    public getGeometry(): IGeometry | null {
        if (this.layer && this.layer.geometry) {
            const type = this.layer.geometry.getType();
            let coordinates;
            if (type === "Point") {
                coordinates = (this.layer
                    .geometry as ymaps.geometry.Point).getCoordinates();
            } else if (type === "Polygon") {
                coordinates = (this.layer
                    .geometry as ymaps.geometry.Polygon).getCoordinates();
            }
            if (coordinates) {
                return {
                    type,
                    coordinates
                };
            }
        }
        return null;
    }

    public changeName(name) {
        this.layer.properties.set("Name", name);
    }

    public changeColor(color) {
        this.layer.properties.set("Color", color);
        this.layer.options.set("fillColor", color);
        this.layer.options.set("strokeColor", color);
    }

    public changeStrokeWidth(strokeWidth) {
        this.layer.options.set("strokeWidth", strokeWidth);
    }

    private isGeometryValid(geoobject: IGeoobject): boolean {
        if (!geoobject.Geometry) {
            return false;
        }
        const geometryType = geometryTypes.find(
            el => geoobject.Geometry.type === el.key
        );
        if (geometryType) {
            return (
                geoobject.Geometry &&
                geometryType.value === geoobject.GeoobjectType
            );
        }
        return false;
    }

    private createLayout(template) {
        return ymaps.templateLayoutFactory.createClass(template);
    }

    private createGeoobject(geometry, { Name, Color, LineWidth }) {
        return new ymaps.GeoObject(
            {
                geometry,
                properties: {
                    needLabel: true,
                    hintContent: Name,
                    Color,
                    Name,
                    IconSize: 18
                }
            },
            {
                iconLayout: "default#imageWithContent",
                iconContentLayout: this.createLayout(templates.markerTemplate),
                iconContentSize: [280, 28],
                iconImageHref: "",
                iconImageSize: [28, 28],
                iconImageOffset: [-28 / 2, -28 / 2],
                fillColor: Color,
                strokeColor: Color,
                interactivityModel: "default#transparent",
                strokeWidth: LineWidth,
                fillOpacity: 0.5
            }
        );
    }

    private getDefaultGeometry(map, geometryType) {
        switch (geometryType) {
            case "Point":
                return {
                    type: "Point",
                    coordinates: map.getCenter()
                };
            case "Polygon":
                const [leftBottomMapBound, rightTopMapBound] = map.getBounds();

                const quarterWidth =
                    (leftBottomMapBound[0] - rightTopMapBound[0]) / 4;
                const quarterHeight =
                    (leftBottomMapBound[1] - rightTopMapBound[1]) / 4;

                const leftBottomPoint = [
                    leftBottomMapBound[0] - quarterWidth,
                    leftBottomMapBound[1] - quarterHeight
                ];
                const rightTopPoint = [
                    rightTopMapBound[0] + quarterWidth,
                    rightTopMapBound[1] + quarterHeight
                ];
                const leftTopPoint = [leftBottomPoint[0], rightTopPoint[1]];
                const rightBottomPoint = [rightTopPoint[0], leftBottomPoint[1]];

                const coordinates = [
                    leftBottomPoint,
                    leftTopPoint,
                    rightTopPoint,
                    rightBottomPoint
                ];
                return {
                    type: "Polygon",
                    coordinates: [coordinates]
                };
            default:
                return null;
        }
    }
}
