import MonitoringYMap from "@/components/Monitoring/MonitoringYMap";
import MonitoringWidget from "@/components/Monitoring/MonitoringWidget/MonitoringWidget";

import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";

import "./MonitoringView.scss";
import { VNode } from "vue";

@Component({
    name: "MonitoringView",
    components: {
        MonitoringWidget,
        MonitoringYMap
    }
})
export default class MonitoringView extends Vue {
    public render(): VNode {
        return (
            <div class="canvas">
                <div class="monitoring-view__body">
                    <div class={["lo-beacons-list", !this.beaconsListVisibility && "lo-beacons-list_hidden"]}>
                        <div class="lo-beacons-list__toggle" onClick={this.toggleBeaconsList}>
                            <i
                                class={[
                                    "fas",
                                    this.$store.state.beaconsListVisibility ? "fa-chevron-down" : "fa-chevron-up"
                                ]}
                            />
                        </div>
                        <monitoring-widget hideControls={false} />
                    </div>
                    <div class="lo-map">
                        <div class="lo-map__inner">
                            <monitoring-y-map class="w-100 h-100" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    @State("beaconsListVisibility")
    private beaconsListVisibility!: boolean;

    private toggleBeaconsList(): void {
        this.$store.commit("toggleBeaconsListVisibility", !this.$store.state.beaconsListVisibility);
    }
}
