import EditGroupModal from "./EditGroupModal";
import messages from "@/components/Mixins/messages";

import { Mixins, Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({
    components: {
        EditGroupModal
    }
})
export default class GroupsModal extends Mixins(messages) {
    @Prop()
    visibility!: boolean;

    group: any = null;
    modal: boolean = false;
    selection: any[] = [];
    filter: string = "";

    @Action("addGroup", { namespace: "monitoring" })
    addGroup;

    @Action("updateGroup", { namespace: "monitoring" })
    updateGroup;

    @Action("deleteGroup", { namespace: "monitoring" })
    deleteGroup;

    handleCreate() {
        this.group = {
            Name: "",
            BeaconIds: []
        };
        this.modal = true;
    }

    async handleCreateGroup(group) {
        await this.addGroup(group);
        this.operationSuccess();
        this.modal = false;
    }

    handleEdit(index, row) {
        this.group = row;
        this.modal = true;
    }

    async handleEditGroup(group) {
        await this.updateGroup(group);
        this.operationSuccess();
        this.modal = false;
    }

    handleDelete(index, row) {
        this.$confirm(this.$t("groupseditor.deleteconfirm").toString(), this.$t("default.warning").toString(), {
            confirmButtonText: this.$t("default.ok").toString(),
            cancelButtonText: this.$t("default.cancel").toString(),
            type: "warning"
        })
            .then(() => {
                this.deleteGroup(row.Id).then(() => {
                    this.operationSuccess();
                });
            })
            .catch(() => {});
    }

    get groups() {
        let groups = this.$store.state.monitoring.groups.filter(el => el.Id !== 0);
        if (this.filter) {
            return groups.filter(el => el.Name.toLowerCase().includes(this.filter.toLowerCase()));
        }
        return groups;
    }

    render() {
        return (
            <el-dialog
                modal-append-to-body
                append-to-body
                close-on-click-modal={false}
                title={this.$t("monitoringwidget.groupsettings")}
                visible={this.visibility}
                onClose={() => this.$emit("visibilityChange", false)}
                width="500px"
            >
                {this.modal ? (
                    <edit-group-modal
                        onCreate={this.handleCreateGroup}
                        onEdit={this.handleEditGroup}
                        visibility={this.modal}
                        onVisibilityChange={(value: boolean) => (this.modal = value)}
                        group={this.group}
                    />
                ) : null}

                <div class="w-100 mb-3 d-flex justify-content-between">
                    <el-input
                        vModel={this.filter}
                        placeholder={this.$t("default.search")}
                        prefix-icon="el-icon-search"
                        class="mr-3"
                        style="flex: 1"
                        size="small"
                    />
                    <el-button size="small" type="primary" icon="el-icon-plus" onClick={this.handleCreate}>
                        {this.$t("groupseditor.add")}
                    </el-button>
                </div>
                <div class="widget-item">
                    <el-table
                        data={this.groups}
                        empty-text={this.$t("default.nodata")}
                        show-header={false}
                        ref="groupsTable"
                    >
                        <el-table-column property="Name" label={this.$t("default.name")} />
                        <el-table-column
                            align="right"
                            label={this.$t("default.actions")}
                            {...{
                                scopedSlots: {
                                    default: scope => (
                                        <div>
                                            <el-button
                                                size="mini"
                                                icon="fas fa-pencil fa-fw"
                                                class="p-2 border-0"
                                                onClick={() => this.handleEdit(scope.$index, scope.row)}
                                            />
                                            <el-button
                                                size="mini"
                                                icon="fas fa-trash fa-fw"
                                                class="p-2 border-0 ml-0"
                                                onClick={() => this.handleDelete(scope.$index, scope.row)}
                                            />
                                        </div>
                                    )
                                }
                            }}
                        />
                    </el-table>
                </div>
            </el-dialog>
        );
    }
}
