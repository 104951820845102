import { Vue, Component } from "vue-property-decorator";

import GroupsModal from "./GroupsModal";
import BeaconsGroup from "./BeaconsGroup";

import MiniBeaconCard from "./MiniBeaconCard";
import BeaconCard from "./BeaconCard";

import sortingTypes from "./Sorting";
import groupingTypes from "./Grouping";
import * as types from "@/store/const/mutation-types";
import { State, Mutation, Action } from "vuex-class";
import IUser from "@/contracts/IUser";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import "./MonitoringWidget.scss";
import { confirmBNWAS } from "@/api/monitoring";

@Component({
    components: {
        GroupsModal,
        BeaconsGroup,
        BeaconCard,
        MiniBeaconCard
    }
})
export default class MonitoringWidget extends Vue {
    groupsModal: boolean = false;
    sorting = localStorage.getItem("monitoring:sorting")
        ? sortingTypes[localStorage.getItem("monitoring:sorting") as any]
        : sortingTypes[0];
    // grouping = localStorage.getItem("monitoring:grouping")
    //     ? groupingTypes[localStorage.getItem("monitoring:grouping") as any]
    //     : groupingTypes[0];
    search: string = "";
    working: boolean = false;

    @State
    loadingData!: boolean;

    @State
    user!: IUser;

    @State("groups", { namespace: "monitoring" })
    groups;

    @State("beaconsPosition", { namespace: "monitoring" })
    beaconsPosition;

    @State("beaconsTrackData", { namespace: "monitoring" })
    beaconsTrackData;

    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    @Mutation(types.SHOW_POSITIONS, { namespace: "monitoring" })
    showPositions;

    @Mutation(types.HIDE_POSITIONS, { namespace: "monitoring" })
    hidePositions;

    @Mutation(types.HIDE_TRACKS, { namespace: "monitoring" })
    hideTracks;

    @Mutation(types.SELECT_BEACON, { namespace: "monitoring" })
    selectBeacon;

    @Mutation(types.SET_FLOATING_PANEL_CURRENT_VIEW)
    setFloatingPanelCurrentView;

    @Action("showTracks", { namespace: "monitoring" })
    showTracks;

    @Action("loadBeacons", { namespace: "monitoring" })
    loadBeacons;

    @State("selected", { namespace: "monitoring" })
    selected!: number | null;

    created() {
        this.folders = JSON.parse(localStorage.getItem("monitoring:folders") || "false");
    }

    private toggleFolders() {
        this.folders = !this.folders;
        localStorage.setItem("monitoring:folders", JSON.stringify(this.folders));
    }

    get extendedGroups() {
        if (this.folders) {
            return groupingTypes[1].rule(this.beacons, this.groups);
        } else {
            return groupingTypes[0].rule(this.beacons, this.groups, this.$t("default.beacons").toString());
        }
    }

    private async confirmBnwas(beacon: IBeaconMonitoring) {
        await confirmBNWAS(beacon.Id, true);
        await this.loadBeacons();
    }

    private handleSettings(value) {
        this.$router.push({
            name: "beacon",
            params: { id: value.Id }
        });
    }

    private handleCommand(sorting) {
        localStorage.setItem("monitoring:sorting", sorting.value);
        this.sorting = sorting;
        // localStorage.setItem("monitoring:grouping", commandInfo.value);
    }

    private handleTogglePosition(beacons, value) {
        let ids = beacons.map(el => el.Id);
        if (value) {
            this.hideTracks(ids);
            this.showPositions(ids);
            this.$store.commit("toggleBeaconsListVisibility", false);
        } else {
            this.hidePositions(ids);
        }
    }

    private handleToggleTracks(beacons, value) {
        if (value) {
            this.handleShowTracks(beacons);
        } else {
            this.hideTracks(beacons.map(el => el.Id));
        }
    }

    private handleShowTracks(beacons) {
        this.working = true;
        this.hidePositions(beacons.map(el => el.Id));
        this.showTracks(beacons).finally(() => {
            this.working = false;
            this.$store.commit("toggleBeaconsListVisibility", false);
        });
    }

    private centerMap(id) {
        this.$root.$emit("centerBeacon", id);
    }

    private handleSelectBeacon(id) {
        this.selectBeacon(id);
        this.setFloatingPanelCurrentView("BeaconSidePage");
    }

    private beaconsCards({ filteredBeacons }: { filteredBeacons: IBeaconMonitoring[] }) {
        if (this.user && this.user.MonitoringInterfaceSettings.CardSize === "small") {
            return filteredBeacons.map(beacon => (
                <mini-beacon-card
                    class="mb-2"
                    beacon={beacon}
                    selected={beacon.Id === this.selected}
                    checkedPosition={this.beaconsPosition.includes(beacon.Id)}
                    checkedTrack={this.beaconsTrackData.some(el => el.Id === beacon.Id)}
                    key={beacon.Id}
                    working={this.working}
                    onCenterBeacon={() => this.centerMap(beacon.Id)}
                    onSelectBeacon={() => this.handleSelectBeacon(beacon.Id)}
                    onTogglePosition={this.handleTogglePosition}
                    onToggleTrack={this.handleToggleTracks}
                    onSettings={this.handleSettings}
                    onConfirmBnwas={this.confirmBnwas}
                />
            ));
        }

        return filteredBeacons.map(beacon => (
            <beacon-card
                class="mb-2"
                beacon={beacon}
                checkedPosition={this.beaconsPosition.includes(beacon.Id)}
                checkedTrack={this.beaconsTrackData.some(el => el.Id === beacon.Id)}
                key={beacon.Id}
                working={this.working}
                onCenterBeacon={() => this.centerMap(beacon.Id)}
                onSelectBeacon={() => this.handleSelectBeacon(beacon.Id)}
                onTogglePosition={this.handleTogglePosition}
                onToggleTrack={this.handleToggleTracks}
                onSettings={this.handleSettings}
                onConfirmBnwas={this.confirmBnwas}
            />
        ));
    }

    private folders: boolean = false;

    render() {
        return (
            <div class="monitoring-widget">
                {this.groupsModal && (
                    <groups-modal
                        visibility={this.groupsModal}
                        onVisibilityChange={(val: boolean) => (this.groupsModal = val)}
                    />
                )}
                <div class="px-2">
                    <div class="d-flex">
                        <el-input
                            placeholder={this.$t("default.search")}
                            class="widget-item mr-2 mb-0"
                            clearable
                            size="small"
                            suffix-icon="el-icon-search"
                            vModel={this.search}
                        >
                            <el-dropdown
                                slot="append"
                                size="small"
                                hide-on-click={false}
                                onCommand={this.handleCommand}
                                trigger="click"
                            >
                                <el-button icon="fad fa-sort-amount-down-alt fa-lg" size="small" />
                                <el-dropdown-menu slot="dropdown">
                                    {sortingTypes.map(sortingType => (
                                        <el-dropdown-item command={sortingType} key={sortingType.value}>
                                            <div
                                                class={[
                                                    this.sorting.value === sortingType.value && "selected-item",
                                                    "mr-4"
                                                ]}
                                            >
                                                {this.$t("default.sorting." + sortingType.key)}
                                            </div>
                                        </el-dropdown-item>
                                    ))}
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-input>
                        <el-tooltip
                            placement="right"
                            open-delay={220}
                            content={
                                this.folders ? this.$t("monitoringwidget.list") : this.$t("monitoringwidget.folders")
                            }
                        >
                            <el-button
                                onClick={this.toggleFolders}
                                class={["checkbox-button fad", this.folders ? "fa-list-ul" : "fa-folder-tree"]}
                                size="small"
                            />
                        </el-tooltip>
                    </div>
                    <div class="d-flex justify-content-between py-2">
                        <div class="d-flex">
                            {this.folders && (
                                <el-button
                                    disabled={!this.user || !this.user.CanEditGroups}
                                    onClick={() => (this.groupsModal = true)}
                                    class="p-0"
                                    size="medium"
                                    type="text"
                                >
                                    {this.$t("monitoringwidget.groupsettings")}
                                </el-button>
                            )}
                        </div>
                    </div>
                </div>
                <div class="grouped-list h-100">
                    {this.extendedGroups.map(group => (
                        <beacons-group
                            group={group}
                            isList={!this.folders}
                            key={group.Id}
                            search={this.search}
                            sort={this.sorting.sortFunction}
                            onTogglePosition={this.handleTogglePosition}
                            onToggleTrack={this.handleToggleTracks}
                            {...{
                                scopedSlots: {
                                    default: this.beaconsCards
                                }
                            }}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
