import { Vue, Component, Prop } from "vue-property-decorator";

import "./MapPointModal.scss";
import { TrackPointState, TrackPointType, TrackSettings } from "../Map/yandex/Track";
import dayjs from "dayjs";
import { TrackPoint } from "@/api/monitoring";
import { CommunicationType } from "@/contracts/IBeaconManagement";
import { StatusEventEnum } from "@/api/models/Timeline";

@Component
export default class MapPointModal extends Vue {
    currentPoint: string = "";

    showPointStates(point: TrackPoint) {
        return (
            <div class="d-flex align-items-center">
                {point.State !== TrackPointState.None && (
                    <div class="d-flex justify-content-center align-items-center mr-1">
                        <i
                            class={[
                                point.State === TrackPointState.PreEmergency
                                    ? "color-glyph-preemergency status-tag fad fa-exclamation-circle"
                                    : point.State === TrackPointState.Emergency
                                    ? "color-glyph-emergency status-tag fad fa-exclamation-circle"
                                    : "",
                                ""
                            ]}
                        />
                    </div>
                )}
                {point.Type !== TrackPointType.None && (
                    <el-tooltip
                        content={
                            point.Type === TrackPointType.Parking
                                ? this.$t("map.parking")
                                : point.Type === TrackPointType.Stop
                                ? this.$t("map.stop")
                                : ""
                        }
                        placement="top"
                    >
                        <div class="d-flex justify-content-center align-items-center mr-1">
                            <i
                                class={[
                                    point.Type === TrackPointType.Parking
                                        ? "color-glyph-primary status-tag fad fa-parking-circle"
                                        : point.Type === TrackPointType.Stop
                                        ? "color-glyph-primary status-tag fad fa-stop-circle"
                                        : "",
                                    ""
                                ]}
                            />
                        </div>
                    </el-tooltip>
                )}
                {point.StatusEvents.length > 0 && (
                    <div class="d-flex justify-content-center align-items-center mr-1">
                        <i class="color-glyph-preemergency status-tag fad fa-question-circle" />
                    </div>
                )}
            </div>
        );
    }

    private timestampCell(scope: { row: TrackPoint }) {
        return (
            <div class="d-flex align-items-center">
                <date-wrapper date={scope.row.Timestamp} format="DD.MM.YY HH:mm" />
            </div>
        );
    }

    private valueCellStyle({ row, columnIndex }: { row: TrackPoint; columnIndex: number }) {
        if (columnIndex === 1) {
            if (row.State === TrackPointState.PreEmergency) {
                return "state-preemergency";
            } else if (row.State === TrackPointState.Emergency) {
                return "state-emergency";
            }
        }
    }

    private showStatusEvents(point: TrackPoint) {
        return (
            <div class="d-flex flex-wrap">
                {point.StatusEvents.map(statusEvent => {
                    return (
                        <el-tag class="mr-1 mb-2" size="small">
                            <span class="mr-1 font-weight-medium"> Событие:</span>
                            <span class="text-muted">
                                {this.$t(`StatusEvent.${StatusEventEnum[statusEvent.EventId]}`)}
                            </span>
                        </el-tag>
                    );
                })}
            </div>
        );
    }

    private showChannelsInfo(point: TrackPoint) {
        return (
            <el-table
                size="mini"
                show-header={false}
                border={true}
                data={point.ChannelData}
                class="w-100 mb-3"
                cell-class-name={this.valueCellStyle}
            >
                <el-table-column align="left" prop="Name" label="Name" />
                <el-table-column align="left" prop="DisplayValue" width="120" label="DisplayValue" />
                <el-table-column
                    align="left"
                    width="120"
                    {...{
                        scopedSlots: {
                            default: this.timestampCell
                        }
                    }}
                />
            </el-table>
        );
    }

    private showCommunicationTag(point: TrackPoint) {
        return point.CommunicationType !== null ? (
            <div class="communication-tag" style="line-height: 22px; font-size: 13px;">
                {point.CommunicationType === CommunicationType.GSM ? "GSM" : "IR"}
            </div>
        ) : null;
    }

    get title() {
        return this.trackData && this.trackData.BeaconName;
    }

    render() {
        return (
            <el-dialog
                modal={false}
                close-on-click-modal={false}
                title={this.title}
                visible={this.visibility}
                onClose={() => this.$emit("visibilityChange", false)}
                width="500px"
                class="map-point-modal"
                top="0"
            >
                <div class="scroll" style="max-height: 400px;">
                    {this.trackPointsProperties.length > 1 ? (
                        this.trackPointsProperties.map(point => (
                            <el-collapse accordion vModel={this.currentPoint}>
                                <el-collapse-item name={dayjs(point.Timestamp).format("DD.MM.YY HH:mm")}>
                                    <div slot="title" class="d-flex w-100 align-items-center">
                                        <div class="d-flex" style="min-width: 100px;">
                                            {dayjs(point.Timestamp).format("DD.MM.YY HH:mm")}
                                        </div>
                                        {this.showPointStates(point)}
                                        {this.showCommunicationTag(point)}
                                    </div>
                                    <div class="d-flex flex-column">
                                        {this.showStatusEvents(point)}
                                        {this.showChannelsInfo(point)}
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        ))
                    ) : this.trackPointsProperties.length === 1 ? (
                        <div class="d-flex flex-column">
                            <div class="d-flex w-100 mb-3">
                                {this.showPointStates(this.trackPointsProperties[0])}
                                {this.showCommunicationTag(this.trackPointsProperties[0])}
                            </div>
                            {this.showStatusEvents(this.trackPointsProperties[0])}
                            {this.showChannelsInfo(this.trackPointsProperties[0])}
                        </div>
                    ) : null}
                </div>
            </el-dialog>
        );
    }

    @Prop() visibility!: boolean;
    @Prop() trackPointsProperties!: TrackPoint[];
    @Prop() trackData!: TrackSettings | undefined;
}
