import { SET_CURRENT_GEOOBJECT_ID } from "@/store/const/mutation-types";
import colors from "@/const/colors";

import cloneDeep from "lodash/cloneDeep";

import GeoobjectsPanel from "@/components/Geoobjects/GeoobjectsPanel";
import GeoobjectsEditor from "@/components/Geoobjects/GeoobjectEditor";
import GeoobjectsMap from "@/components/Geoobjects/GeoobjectsYMap";

import messages from "@/components/Mixins/messages";

import { Component, Mixins, Watch } from "vue-property-decorator";

import { namespace } from "vuex-class";

const geoobjects = namespace("geoobjects");

@Component({
    name: "HistoryView",
    components: {
        GeoobjectsPanel,
        GeoobjectsEditor,
        GeoobjectsMap
    }
})
export default class GeoobjectsView extends Mixins(messages) {
    render() {
        let geoobjectsEditor: any = null;
        if (this.showEditor) {
            geoobjectsEditor = (
                <geoobjects-editor
                    vModel={this.currentGeoobjectBuffer}
                    onCancel={this.handleCancelEditGeoobject}
                    onSave={this.handleSaveGeoobject}
                />
            );
        }

        return (
            <div class="canvas">
                <div class="lo-panel">
                    {geoobjectsEditor}
                    <geoobjects-panel
                        onSelect={this.handleSelect}
                        onEdit={this.handleEdit}
                        onCreate={this.handleCreate}
                        onDelete={this.handleDelete}
                        geoobjects={this.geoobjects}
                        selected={this.currentGeoobjectBuffer}
                    />
                </div>
                <geoobjects-map
                    geoobject={this.currentGeoobjectBuffer}
                    editable={this.showEditor}
                    onUpdateGeometry={this.updateGeometry}
                    class="w-100 h-100"
                />
            </div>
        );
    }

    currentGeoobjectBuffer: any = {};
    showEditor: boolean = false;

    @geoobjects.State("geoobjects")
    geoobjects;

    @geoobjects.Getter("currentGeoobject")
    currentGeoobject;

    @geoobjects.Mutation(SET_CURRENT_GEOOBJECT_ID)
    setCurrentGeoobjectId;

    @geoobjects.Action
    updateGeoobject;

    @geoobjects.Action
    createGeoobject;

    @geoobjects.Action
    deleteGeoobject;

    @Watch("currentGeoobject")
    onCurrentGeoobjectChanged(value) {
        this.currentGeoobjectBuffer = cloneDeep(value);
    }

    handleSelect(geoobject) {
        this.setCurrentGeoobjectId(geoobject.Id);
    }

    handleEdit(geoobject) {
        this.setCurrentGeoobjectId(geoobject.Id);
        this.showEditor = true;
    }

    handleCreate(geoobject) {
        this.currentGeoobjectBuffer = {
            Id: -1,
            Name: "",
            Comment: "",
            GeoobjectType: 2,
            InterfaceSettings: {
                color: colors[0],
                lineWidth: 5
            },
            Geometry: null
        };

        this.showEditor = true;
    }

    handleSaveGeoobject() {
        if (~this.currentGeoobjectBuffer.Id) {
            this.handleUpdateGeoobject();
        } else {
            this.handleCreateGeoobject();
        }
    }

    async handleUpdateGeoobject() {
        this.showEditor = false;

        try {
            await this.updateGeoobject(this.currentGeoobjectBuffer);
            this.operationSuccess();
        } catch (error) {
            this.operationFailed();
        }
    }

    async handleCreateGeoobject() {
        this.showEditor = false;

        try {
            await this.createGeoobject(this.currentGeoobjectBuffer);
            this.operationSuccess();
        } catch (error) {
            this.operationFailed();
        }
    }

    async handleDelete(geoobject) {
        try {
            await this.deleteGeoobject(geoobject.Id);
            this.operationSuccess();
        } catch (error) {
            this.operationFailed();
        }
    }

    handleCancelEditGeoobject() {
        this.showEditor = false;
        this.currentGeoobjectBuffer = cloneDeep(this.currentGeoobject);
    }

    updateGeometry(geometry) {
        this.currentGeoobjectBuffer.Geometry = geometry;
    }
}
