import Track from "../Map/yandex/Track";
import PosititonMarker from "../Map/yandex/PositionMarker";
import YandexMap from "../Map/yandex/YandexMap";
import Geoobject from "../Map/yandex/Geoobject";

export interface BeaconMapLayers {
    Id: number;
    Track?: Track;
    PosititonMarker?: PosititonMarker;
}

export interface MapStore {
    Map: YandexMap | null;
    BeaconMapLayers: BeaconMapLayers[];
    GeoobjectsLayers: GeoobjectLayer[];
}

export interface GeoobjectLayer {
    Id: number;
    Geoobject: Geoobject;
}

export function createMapStore(): MapStore {
    return {
        Map: null,
        BeaconMapLayers: [],
        GeoobjectsLayers: []
    };
}

export function findBeaconMapLayers(mapStore: MapStore, Id: number): BeaconMapLayers | undefined {
    return mapStore.BeaconMapLayers.find(data => data.Id === Id);
}

export function createBeaconMapLayers(mapStore: MapStore, Id: number): BeaconMapLayers {
    const newBeaconMapLayers: BeaconMapLayers = {
        Id: Id
    };
    mapStore.BeaconMapLayers.push(newBeaconMapLayers);
    return newBeaconMapLayers;
}
