import YMap from "@/components/Map/yandex/YMap";
import YandexMap from "@/components/Map/yandex/YandexMap.ts";

import loDifferenceBy from "lodash/differenceBy";
import loDifferenceWith from "lodash/differenceWith";

import dayjs from "dayjs";

import PositionMarker from "@/components/Map/yandex/PositionMarker";
import Geoobject from "@/components/Map/yandex/Geoobject";
import MapPointModal from "@/components/Monitoring/MapPointModal";

import {
    createMapStore,
    createBeaconMapLayers,
    findBeaconMapLayers,
    GeoobjectLayer,
    MapStore
} from "@/components/Mixins/mapdata";

import { Component, Watch, Vue } from "vue-property-decorator";

import { Getter, State, Mutation } from "vuex-class";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import Track, { TrackSettings } from "../Map/yandex/Track";
import * as types from "@/store/const/mutation-types";
import { trackData } from "@/store/modules/monitoring";
import { TrackPoint } from "@/api/monitoring";
import IGeoobject from "@/contracts/IGeoobject";
import IUser from "@/contracts/IUser";

const mapStore: MapStore = createMapStore();

@Component({
    components: {
        YMap,
        MapPointModal
    }
})
export default class MonitoringMap extends Vue {
    @State
    decoratorOptions;

    @State
    maxMarkerVisibilityZoom;

    showTrack(trackSettingsArray: TrackSettings[]) {
        const { Map } = mapStore;
        if (!Map) {
            return;
        }

        let layersToFocus: Track[] = [];
        for (const trackSettings of trackSettingsArray) {
            let beaconMapLayers = findBeaconMapLayers(mapStore, trackSettings.Id);
            if (!beaconMapLayers) {
                beaconMapLayers = createBeaconMapLayers(mapStore, trackSettings.Id);
            }

            const trackDataTemp = trackData.find(x => x.Id === trackSettings.Id);
            if (trackDataTemp) {
                const oldTrack = beaconMapLayers.Track;

                const newTrack = new Track(Map, trackSettings, trackDataTemp, this.showTrackPointModal);

                if (oldTrack) {
                    oldTrack.hide();
                } else {
                    beaconMapLayers.Track = newTrack;
                    layersToFocus.push(newTrack);
                }

                newTrack.show();
            }
        }

        if (layersToFocus.length > 0) {
            Map.focusToLayers(layersToFocus);
        }
    }

    hideTrack(trackSettingsArray: TrackSettings[]) {
        for (const trackSettings of trackSettingsArray) {
            const beaconMapLayers = findBeaconMapLayers(mapStore, trackSettings.Id);
            if (beaconMapLayers && beaconMapLayers.Track) {
                beaconMapLayers.Track.hide();
                beaconMapLayers.Track = undefined;
            }
        }
    }

    render() {
        return (
            <div id="map" class="monitoring-map" style="z-index: 0">
                {this.mapPointModalVisibility ? (
                    <map-point-modal
                        trackData={this.currentTrackData}
                        trackPointsProperties={this.trackPoints}
                        visibility={this.mapPointModalVisibility}
                        onVisibilityChange={this.handleVisibilityChange}
                    />
                ) : null}
                <y-map onScriptIsLoaded={() => this.initMap()} />
            </div>
        );
    }

    mapPointModalVisibility: boolean = false;
    trackPoints: any[] = [];

    @State
    user!: IUser;

    @State mapOptions;
    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    @Getter("getBeaconsPosition", { namespace: "monitoring" })
    getBeaconsPosition!: IBeaconMonitoring[];
    @State("beaconsTrackData", { namespace: "monitoring" })
    beaconsTrackData;
    @Getter("selectedGeoobjects", { namespace: "geoobjects" })
    selectedGeoobjects;

    @Watch("selectedGeoobjects")
    onSelectedGeoobjectsChange(value) {
        this.showGeoobjects(value);
        this.hideGeoobjects(value);
    }

    @Watch("getBeaconsPosition")
    onGetBeaconsPositionChanged(beacons: IBeaconMonitoring[], oldBeacons: IBeaconMonitoring[]): void {
        // Найти маяки которые нужно показать на карте
        let beaconsToShow = loDifferenceWith(beacons, oldBeacons, this.compareBeaconsLocation);
        this.showPosition(beaconsToShow);
        // Найти маяки который должны быть скрыты с карты
        let beaconsToHide = loDifferenceBy(oldBeacons, beacons, "Id");
        this.hidePosition(beaconsToHide);
    }

    @Watch("beaconsTrackData")
    onBeaconsTrackDataChanged(tracksSettings: TrackSettings[], oldTracksSettings: TrackSettings[]) {
        let tracksToShow = loDifferenceWith(tracksSettings, oldTracksSettings, this.compareTracksSettings);
        this.showTrack(tracksToShow);
        let tracksToHide = loDifferenceBy(oldTracksSettings, tracksSettings, "Id");
        this.hideTrack(tracksToHide);
    }

    handleVisibilityChange(event: boolean) {
        this.mapPointModalVisibility = event;
    }

    showGeoobjects(geoobjects: IGeoobject[]) {
        const { Map, GeoobjectsLayers } = mapStore;
        if (!Map) {
            return;
        }

        let geoobjectsToFocus: any[] = [];
        for (const geoobject of geoobjects) {
            if (!GeoobjectsLayers.some(el => el.Id === geoobject.Id)) {
                const geoobjectData: GeoobjectLayer = {
                    Id: geoobject.Id,
                    Geoobject: new Geoobject(Map, geoobject)
                };
                GeoobjectsLayers.push(geoobjectData);
                geoobjectsToFocus.push(geoobjectData.Geoobject);
                geoobjectData.Geoobject.show();
            }
        }

        if (geoobjectsToFocus.length > 0) {
            Map.focusToLayers(geoobjectsToFocus);
        }
    }

    hideGeoobjects(geoobjects: IGeoobject[]) {
        let { GeoobjectsLayers } = mapStore;
        mapStore.GeoobjectsLayers = GeoobjectsLayers.filter(el => {
            if (geoobjects.some(geoobject => geoobject.Id === el.Id)) {
                return true;
            } else {
                el.Geoobject.hide();
                return false;
            }
        });
    }

    initMap() {
        mapStore.Map = new YandexMap("map", { ...this.mapOptions });
    }

    compareBeaconsLocation(arrVal: IBeaconMonitoring, othVal: IBeaconMonitoring) {
        return (
            arrVal.Id === othVal.Id &&
            dayjs(othVal.Location ? othVal.Location.Timestamp : 0).isSame(
                dayjs(arrVal.Location ? arrVal.Location.Timestamp : 0)
            ) &&
            arrVal.BNWASAlarm === othVal.BNWASAlarm &&
            arrVal.BNWASConfirm === othVal.BNWASConfirm &&
            arrVal.IconPath === othVal.IconPath
        );
    }

    compareTracksSettings(arrVal: TrackSettings, othVal: TrackSettings) {
        return (
            arrVal.Id === othVal.Id && dayjs(othVal.LastCoordinateDt || 0).isSame(dayjs(arrVal.LastCoordinateDt || 0))
        );
    }

    selectBeacon(id: number) {
        let beaconMapLayer = findBeaconMapLayers(mapStore, id);
        if (beaconMapLayer) {
            if (beaconMapLayer.Track) {
                beaconMapLayer.Track.focus();
            } else if (beaconMapLayer.PosititonMarker) {
                beaconMapLayer.PosititonMarker.focus();
            }
        }
    }

    showPosition(beacons: IBeaconMonitoring[]) {
        const { Map } = mapStore;
        if (!Map) {
            return;
        }

        let layersToFocus: any[] = [];
        for (const beacon of beacons) {
            let beaconMapLayers = findBeaconMapLayers(mapStore, beacon.Id);
            if (!beaconMapLayers) {
                beaconMapLayers = createBeaconMapLayers(mapStore, beacon.Id);
            }

            const positionMarker = new PositionMarker(Map, beacon, this.showSidePage, this.user.BnwasManagement);
            if (beaconMapLayers.PosititonMarker) {
                beaconMapLayers.PosititonMarker.hide();
                beaconMapLayers.PosititonMarker = positionMarker;
            } else {
                layersToFocus.push(positionMarker);
                beaconMapLayers.PosititonMarker = positionMarker;
            }

            positionMarker.show();
        }

        if (layersToFocus.length > 1) {
            Map.focusToLayers(layersToFocus);
        } else if (layersToFocus.length === 1) {
            layersToFocus[0].focus();
        }
    }

    hidePosition(beacons: IBeaconMonitoring[]) {
        for (const beacon of beacons) {
            let beaconMapLayers = findBeaconMapLayers(mapStore, beacon.Id);
            if (beaconMapLayers && beaconMapLayers.PosititonMarker) {
                beaconMapLayers.PosititonMarker.hide();
                beaconMapLayers.PosititonMarker = undefined;
            }
        }
    }

    private currentTrackData: TrackSettings | undefined = undefined;

    showTrackPointModal(trackSettings: TrackSettings, trackPoints: TrackPoint[]): void {
        if (trackSettings && trackPoints) {
            this.currentTrackData = trackSettings;
            this.trackPoints = trackPoints;
            this.mapPointModalVisibility = true;
        }
    }

    @Mutation(types.SET_FLOATING_PANEL_CURRENT_VIEW)
    setFloatingPanelCurrentView;

    @Mutation(types.SELECT_BEACON, { namespace: "monitoring" })
    selectBeaconSidePage;

    private handleSelectBeacon(id) {
        this.selectBeaconSidePage(id);
        this.setFloatingPanelCurrentView("BeaconSidePage");
    }

    showSidePage(event): void {
        this.handleSelectBeacon(event.Id);
    }

    mounted() {
        this.$root.$on("centerBeacon", value => {
            this.selectBeacon(value);
        });
    }

    activated() {
        this.$nextTick(() => {
            const { Map } = mapStore;
            if (Map) {
                Map.fitToViewport();
            }
        });
    }
}
