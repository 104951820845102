export default getLocale => {
    return {
        firstDayOfWeek: 1,
        shortcuts: [
            {
                text: getLocale("default.dateshortcuts.twodays"),
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
                    picker.$emit("pick", [start, end]);
                }
            },
            {
                text: getLocale("default.dateshortcuts.week"),
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit("pick", [start, end]);
                }
            },
            {
                text: getLocale("default.dateshortcuts.month"),
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit("pick", [start, end]);
                }
            },
            {
                text: getLocale("default.dateshortcuts.threemonths"),
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit("pick", [start, end]);
                }
            }
        ]
    };
};
