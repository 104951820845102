import { Vue, Prop, Component } from "vue-property-decorator";
import IBeaconMonitoring, { CommonState } from "@/contracts/IBeaconMonitoring";

import IconBase from "@/components/Icons/IconBase.vue";
import beaconBattery from "@/components/Monitoring/MonitoringWidget/BeaconBattery";

import states from "@/const/beacon-states";

import "./BeaconCard.scss";
import { RelevanceState, ChannelValueState } from "@/contracts/IChannelMonitoring";

@Component({
    components: {
        IconBase,
        BeaconBattery: beaconBattery as any
    }
})
export default class BeaconCard extends Vue {
    @Prop() beacon!: IBeaconMonitoring;
    @Prop() working!: boolean;
    @Prop() checkedPosition!: boolean;
    @Prop() checkedTrack!: boolean;

    states = states;

    selectBeacon() {
        this.$emit("selectBeacon");
    }

    centerBeacon() {
        this.$emit("centerBeacon");
    }

    togglePosition() {
        this.$emit("togglePosition", [this.beacon], !this.checkedPosition);
    }

    toggleTrack() {
        this.$emit("toggleTrack", [this.beacon], !this.checkedTrack);
    }

    handleCommand(command) {
        this.$emit(command, this.beacon);
    }

    render() {
        return (
            <div onClick={this.selectBeacon} class="beacon-card lo-card">
                <div class="d-flex flex-column flex-grow-1" style="min-width: 0;">
                    <div class="d-flex align-items-center mb-2">
                        <div class="d-flex justify-content-center align-items-center h-100">
                            <div
                                style={{
                                    backgroundColor: `${this.beacon.UserInterfaceSettings.Color}22`
                                }}
                                class="rounded-circle mr-2"
                            >
                                <icon-base
                                    iconName={this.beacon.IconPath}
                                    iconColor={this.beacon.UserInterfaceSettings.Color}
                                />
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center w-100" style="min-width: 0">
                            <div class="text-truncate nopointer font-size-s font-weight-medium">{this.beacon.Name}</div>
                            <div
                                class="d-flex"
                                onClick={event => {
                                    event.stopPropagation();
                                }}
                            >
                                <el-checkbox-button
                                    class="lo-card-button beacon-card__button fa fa-crosshairs mr-2"
                                    value={this.checkedPosition}
                                    disabled={
                                        this.working || !this.beacon.Location || !this.beacon.Location.Coordinates
                                    }
                                    onChange={this.togglePosition}
                                />
                                <el-checkbox-button
                                    class="lo-card-button beacon-card__button fas fa-route mr-2"
                                    disabled={this.working || !this.beacon.HasTrack}
                                    value={this.checkedTrack}
                                    onChange={this.toggleTrack}
                                />
                                <el-dropdown trigger="click" onCommand={this.handleCommand}>
                                    <el-button class="lo-card-button beacon-card__button fas fa-ellipsis-v" />
                                    <el-dropdown-menu slot="dropdown">
                                        {this.beacon.BNWASAlarm && !this.beacon.BNWASConfirm ? (
                                            <el-dropdown-item
                                                class="beacon-card__command beacon-card__command_emergency"
                                                command="confirmBnwas"
                                            >
                                                {this.$t("bnwas.confirm")}
                                            </el-dropdown-item>
                                        ) : null}

                                        <el-dropdown-item class="beacon-card__command" command="settings">
                                            {this.$t("default.settings")}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <div class="d-flex justify-content-start align-items-center">
                            <div class="d-flex justify-content-start align-items-center font-size-xs nopointer mr-3">
                                <div class="datetime-tag">
                                    <date-wrapper date={this.beacon.ConnectionDt} format="DD.MM.YY HH:mm" />
                                </div>
                            </div>
                            <beacon-battery batteryCharge={this.beacon.Charge} />
                        </div>
                        <div class="d-flex justify-content-end align-items-center">
                            {this.beacon.BNWASAlarm ? (
                                <div class="d-flex justify-content-center align-items-center">
                                    <span
                                        class={[
                                            states.bnwasStates[
                                                this.beacon.BNWASAlarm ? (this.beacon.BNWASConfirm ? 2 : 1) : 0
                                            ].class,
                                            "bnwas-tag px-2 mr-2"
                                        ]}
                                    >
                                        {this.$t("bnwas.bnwas")}
                                    </span>
                                </div>
                            ) : null}
                            <div class="d-flex justify-content-center align-items-center">
                                <i class={[states.generalStates[this.beacon.GeneralState].class, "status-tag"]} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
