import { Vue, Prop, Component } from "vue-property-decorator";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";

import IconBase from "@/components/Icons/IconBase.vue";
import beaconBattery from "@/components/Monitoring/MonitoringWidget/BeaconBattery";

import states from "@/const/beacon-states";

import "./MiniBeaconCard.scss";
import { CommunicationType } from "@/contracts/IBeaconManagement";
import dayjs from "dayjs";

@Component({
    components: {
        IconBase,
        BeaconBattery: beaconBattery as any
    }
})
export default class BeaconCard extends Vue {
    @Prop() beacon!: IBeaconMonitoring;
    @Prop() working!: boolean;
    @Prop() checkedPosition!: boolean;
    @Prop() checkedTrack!: boolean;
    @Prop() selected!: boolean;

    states = states;

    selectBeacon() {
        this.$emit("selectBeacon");
    }

    centerBeacon() {
        this.$emit("centerBeacon");
    }

    togglePosition() {
        this.$emit("togglePosition", [this.beacon], !this.checkedPosition);
    }

    toggleTrack() {
        this.$emit("toggleTrack", [this.beacon], !this.checkedTrack);
    }

    handleCommand(command) {
        this.$emit(command, this.beacon);
    }

    get bnwasChannel() {
        return this.beacon.Channels.find(x => x.ChannelFlag === 11);
    }

    render() {
        return (
            <div
                onClick={this.selectBeacon}
                class={["mini-beacon-card lo-card", this.selected && "mini-beacon-card_selected"]}
            >
                <div class="d-flex flex-column flex-grow-1" style="min-width: 0;">
                    <div class="d-flex align-items-center mb-1">
                        <div class="d-flex justify-content-center align-items-center h-100">
                            <div
                                style={{
                                    backgroundColor: `${this.beacon.UserInterfaceSettings.Color}22`
                                }}
                                class="rounded-circle mr-2"
                            >
                                <icon-base
                                    iconWidth={24}
                                    iconHeight={24}
                                    iconName={this.beacon.IconPath}
                                    iconColor={this.beacon.UserInterfaceSettings.Color}
                                />
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center w-100" style="min-width: 0">
                            <div class="d-flex align-items-center" style="min-width: 0">
                                {this.beacon.LastCommunicationType !== null ? (
                                    <div class="communication-tag">
                                        {this.beacon.LastCommunicationType === CommunicationType.GSM ? "GSM" : "IR"}
                                    </div>
                                ) : null}
                                <div class="text-truncate nopointer font-size-xs font-weight-medium">
                                    {this.beacon.Name}
                                </div>
                            </div>
                            <div
                                class="d-flex"
                                onClick={event => {
                                    event.stopPropagation();
                                }}
                            >
                                <el-checkbox-button
                                    class="lo-card-button mini-beacon-card__button fas fa-crosshairs mr-2"
                                    value={this.checkedPosition}
                                    disabled={
                                        this.working || !this.beacon.Location || !this.beacon.Location.Coordinates
                                    }
                                    onChange={this.togglePosition}
                                />
                                <el-checkbox-button
                                    class="lo-card-button mini-beacon-card__button fas fa-route mr-2"
                                    disabled={this.working || !this.beacon.HasTrack}
                                    value={this.checkedTrack}
                                    onChange={this.toggleTrack}
                                />
                                <el-dropdown size="small" trigger="click" onCommand={this.handleCommand}>
                                    <el-button class="lo-card-button mini-beacon-card__button fas fa-ellipsis-v fa-lg" />
                                    <el-dropdown-menu slot="dropdown">
                                        {this.beacon.BNWASAlarm && !this.beacon.BNWASConfirm ? (
                                            <el-dropdown-item
                                                class="mini-beacon-card__command mini-beacon-card__command_emergency"
                                                command="confirmBnwas"
                                            >
                                                {this.$t("bnwas.confirm")}
                                            </el-dropdown-item>
                                        ) : null}

                                        <el-dropdown-item class="mini-beacon-card__command" command="settings">
                                            {this.$t("default.settings")}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <div class="d-flex justify-content-start align-items-center">
                            <div class="d-flex justify-content-start align-items-center font-size-xs nopointer mr-1">
                                <div class="datetime-tag">
                                    <date-wrapper date={this.beacon.ConnectionDt} format="DD.MM.YY HH:mm" />
                                </div>
                            </div>
                            <div class="channel-value-tag nopointer px-1 mr-1 text-right">
                                <i class="fa-xs fad fa-tachometer-fast" />
                                <div class="font-size-xs" style="min-width: 55px;">
                                    {this.beacon.FormattedSpeed}
                                </div>
                            </div>
                            <div class="channel-value-tag nopointer px-1 text-right">
                                <i class="fa-xs fad fa-location-arrow" />
                                <div class="font-size-xs" style="min-width: 45px;">
                                    {this.beacon.FormattedCourse}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center" style="min-width: 85px;">
                            <div class="d-flex justify-content-center align-items-center">
                                {this.bnwasChannel ? (
                                    <span
                                        class={[
                                            !this.bnwasChannel.Timestamp ||
                                            dayjs().subtract(1, "d") > dayjs(this.bnwasChannel.Timestamp)
                                                ? "state-nodata-inverse"
                                                : this.beacon.BNWASShowStatus || this.bnwasChannel.Value === 0
                                                ? states.bnwasModeStates[
                                                      this.bnwasChannel.Value !== null ? this.bnwasChannel.Value : 1
                                                  ].class
                                                : "state-undefined",
                                            "bnwas-tag mr-1 bnwas-tag_small"
                                        ]}
                                    >
                                        {this.bnwasChannel &&
                                            (this.bnwasChannel.Value === 0
                                                ? "НЕОПР"
                                                : this.bnwasChannel.Value === 1
                                                ? "АВТО"
                                                : this.bnwasChannel.Value === 2
                                                ? "РУЧН"
                                                : this.bnwasChannel.Value === 3
                                                ? "ВЫКЛ"
                                                : this.bnwasChannel.Value === 4
                                                ? "ОТКЛП"
                                                : this.$t("bnwas.bnwas"))}
                                    </span>
                                ) : null}
                            </div>
                            <div class="d-flex justify-content-center align-items-center">
                                <i class={[states.generalStates[this.beacon.GeneralState].class, "status-tag"]} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
