import loOrderBy from "lodash/orderBy";
export default [
    {
        value: 1,
        key: "nameaz",
        sortFunction: array => loOrderBy(array, ["Name"], ["asc"])
    },
    {
        value: 2,
        key: "nameza",
        sortFunction: array => loOrderBy(array, ["Name"], ["desc"])
    }
];
