import { Vue, Component, Prop } from "vue-property-decorator";
import { BnwasReport, SegmentType } from "@/api/report";

@Component
export default class BnwasReportRow extends Vue {
    @Prop()
    bnwasReport!: BnwasReport;

    private shouldShowSegments: boolean = false;

    public render() {
        return (
            <tbody>
                <tr class="MonospacedCell">
                    <td class="p-3">
                        <el-button
                            type="mini"
                            class="border-0 mr-2"
                            onClick={() => (this.shouldShowSegments = !this.shouldShowSegments)}
                        >
                            {this.shouldShowSegments ? (
                                <i class="fas fa-chevron-down"></i>
                            ) : (
                                <i class="fas fa-chevron-right"></i>
                            )}
                        </el-button>
                        {this.bnwasReport.Id}
                    </td>
                    <td class="p-3">{this.bnwasReport.Name}</td>
                    <td class="text-right p-3">{this.bnwasReport.Distance.toFixed(3)} км</td>
                    <td class="text-right p-3">
                        <duration-wrapper duration={this.bnwasReport.MovementTime}></duration-wrapper>
                    </td>
                    <td class="text-right p-3">
                        <duration-wrapper duration={this.bnwasReport.NormalTime}></duration-wrapper>
                    </td>
                    <td class="text-right p-3">{this.bnwasReport.NormalTimePercentage}%</td>
                    <td class="text-right p-3">
                        <duration-wrapper duration={this.bnwasReport.AlarmTime}></duration-wrapper>
                    </td>
                    <td class="text-right p-3">{this.bnwasReport.AlarmTimePercentage}%</td>
                </tr>
                {this.shouldShowSegments && (
                    <tr>
                        <td colspan="8" class="p-0">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-right">{this.$t("geoobjects.type")}</th>
                                        <th class="text-right">{this.$t("default.BNWASDistance")} </th>
                                        <th class="text-right">{this.$t("default.timestamp")} </th>
                                        <th class="text-right">{this.$t("default.BNWASStatusNormalTime")} </th>
                                        <th class="text-right">{this.$t("default.BNWASAlarmStatusTime")} </th>
                                        <th class="text-right">{this.$t("default.startTime")}</th>
                                        <th class="text-right">{this.$t("default.endTime")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.bnwasReport.Segments.map(segment => (
                                        <tr class="MonospacedCell">
                                            <td>{segment.Type === SegmentType.Move ? "Движение" : "Остановка"}</td>
                                            <td class="text-right">{segment.Distance.toFixed(3)} км</td>
                                            <td class="text-right">
                                                <duration-wrapper duration={segment.Time}></duration-wrapper>
                                            </td>
                                            <td class="text-right">
                                                <duration-wrapper duration={segment.NormalTime}></duration-wrapper>
                                            </td>
                                            <td class="text-right">
                                                <duration-wrapper duration={segment.AlarmTime}></duration-wrapper>
                                            </td>
                                            <td class="text-right">
                                                <date-wrapper date={segment.StartDt} format="DD.MM.YY HH:mm" />
                                            </td>
                                            <td class="text-right">
                                                <date-wrapper date={segment.EndDt} format="DD.MM.YY HH:mm" />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
}
