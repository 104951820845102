import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { VNode } from "vue";
import TimelineElement from "@/api/models/Timeline";
import TimelineItem from "@/components/History/TimelineItem";
import Empty from "@/components/Core/Empty";
import "./TimelinePanel.scss";
import { PointClickEvent } from "./HistoryMap";

@Component({
    components: {
        Empty,
        TimelineItem
    }
})
export default class TimelinePanel extends Vue {
    @Prop()
    private timelineElements!: TimelineElement[];

    @Prop()
    private timestamp!: string;

    @Watch("timestamp")
    onTimestampChanged() {
        let currentElement = document.getElementById(this.timestamp.toString());
        if (currentElement) {
            currentElement.scrollIntoView({ block: "center", behavior: "smooth" });
        }
    }

    private onLocationClick(event: PointClickEvent) {
        this.$emit("locationClick", event);
    }

    public render(): VNode {
        return (
            <div class="d-flex flex-column scroll w-100">
                {this.timelineElements.length > 0 ? (
                    <div class="Timeline">
                        {this.timelineElements.map(x => (
                            <timeline-item
                                ref={x.Timestamp}
                                timelineElement={x}
                                timestamp={this.timestamp}
                                onLocationClick={this.onLocationClick}
                            />
                        ))}
                    </div>
                ) : (
                    <empty icon="fas fa-history fa-5x" text={this.$t("default.nodata")} />
                )}
            </div>
        );
    }
}
