class EventEmitter {
    public ymapReady: boolean;
    public scriptIsNotAttached: boolean;
    public initMap;
    private events;

    constructor() {
        this.events = {};
        this.ymapReady = false;
        this.scriptIsNotAttached = true;
    }

    public $on(eventName, fn) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }

        this.events[eventName].push(fn);

        return () => {
            this.events[eventName] = this.events[eventName].filter(
                eventFn => fn !== eventFn
            );
        };
    }

    public $emit(eventName, data?: any) {
        const event = this.events[eventName];
        if (event) {
            event.forEach(fn => fn(data));
        }
    }
}

export const emitter = new EventEmitter();
