/* global ymaps */
import templates from "./templates";
import YandexMap from "@/components/Map/yandex/YandexMap";
import { TrackPoint } from "@/api/monitoring";
import { TrackData } from "@/store/modules/tracks";

import loIsNil from "lodash/isNil";

export enum TrackPointState {
    None,
    Emergency,
    PreEmergency
}

export enum TrackPointType {
    None,
    Stop,
    Parking
}

export interface TrackSettings {
    Id: number;
    BeaconId: number;
    BeaconName: string;
    Color: string;
    IconPath: string;
    TrackLineWidth: number;
    StartDt: Date;
    EndDt: Date;
    LastCoordinateDt: string | Date | undefined;
}

export type ClickHandler = (trackSettings: TrackSettings, trackPoints: TrackPoint[]) => void;

export default class Track {
    private map: YandexMap;
    private trackSettings: TrackSettings;
    private layer: ymaps.GeoObjectCollection;
    private pointsClusterer: ymaps.Clusterer;
    private lastPoint: ymaps.Placemark | undefined;
    private firstPoint: ymaps.Placemark | undefined;
    private clickHandler?: ClickHandler;
    private trackData: TrackData;

    constructor(map: YandexMap, trackSettings: TrackSettings, trackData: TrackData, clickHandler?: ClickHandler) {
        this.map = map;
        this.clickHandler = clickHandler;
        this.trackSettings = trackSettings;
        this.trackData = trackData;

        this.layer = new ymaps.GeoObjectCollection();
        this.pointsClusterer = this.initPointsClusterer();
        if (clickHandler) {
            this.pointsClusterer.events.add("click", this.onClusterClick.bind(this));
        }

        this.layer.add(this.pointsClusterer);

        if (trackData.Coordinates && trackData.Coordinates.length > 0) {
            if (trackData.Coordinates.length > 1) {
                this.lastPoint = this.createLastPoint(
                    trackSettings,
                    trackData.TrackPoints[trackData.Coordinates.length - 1],
                    trackData.Coordinates.length - 1
                );

                this.lastPoint.events.add("click", this.onPlacemarkClick.bind(this));
                this.layer.add(this.lastPoint);
            }

            this.firstPoint = this.createFirstPoint(trackSettings, trackData.TrackPoints[0]);
            this.firstPoint.events.add("click", this.onPlacemarkClick.bind(this));
            this.layer.add(this.firstPoint);
            if (trackData.Coordinates.length > 2) {
                const trackPoints = this.createTrackPoints(trackSettings, trackData.TrackPoints);
                this.pointsClusterer.add(trackPoints);
            }

            const trackLine = this.createTrackLine(trackSettings, trackData.Coordinates);
            if (trackLine) {
                this.layer.add(trackLine);
            }
        }
    }

    private onClusterClick(event: object | ymaps.IEvent) {
        const target: any = (event as ymaps.IEvent).get("target");
        if (target) {
            let geoobjects: any[] = target.properties.get("geoObjects");
            if (geoobjects) {
                const trackPoints: any[] = geoobjects.map((placemark) => {
                    const placemarkProps: any = (placemark as ymaps.Placemark).properties.getAll();
                    const trackPoint = { ...this.trackData.TrackPoints[placemarkProps.Index] };
                    return trackPoint;
                });

                if (this.clickHandler) this.clickHandler(this.trackSettings, trackPoints);
            }
        }
    }

    private onPlacemarkClick(event: object | ymaps.IEvent) {
        const target = (event as ymaps.IEvent).get("target");
        if (target) {
            const placemarkProps: any = (target as ymaps.Placemark).properties.getAll();
            const trackPoint = { ...this.trackData.TrackPoints[placemarkProps.Index] };
            if (this.clickHandler) this.clickHandler(this.trackSettings, [trackPoint]);
        }
    }

    public getBounds() {
        return this.layer.getBounds();
    }

    public focus() {
        if (this.map.isLayerOnMap(this.layer)) {
            const bounds = this.layer.getBounds();
            this.map.setBounds(bounds);
        }
    }

    public show() {
        if (!this.map.isLayerOnMap(this.layer)) {
            this.map.addLayer(this.layer);
        }
    }

    public hide() {
        this.map.removeLayer(this.layer);
    }

    private createFirstPoint(trackSettings: TrackSettings, trackPoint: TrackPoint) {
        return new ymaps.Placemark(
            trackPoint.Coordinates,
            {
                Index: 0,
                Name: "Start",
                Color: trackSettings.Color,
                IconPath: trackSettings.IconPath,
                IconSize: 18,
                needLabel: true,
                State: trackPoint.State,
                Type: trackPoint.Type,
                Course: trackPoint.Course
            },
            this.getPlacemarkOptions(
                {
                    iconContentSize: [100, 18],
                    iconImageSize: [18, 18],
                    iconImageOffset: [-18 / 2, -18 / 2],
                    iconColor: trackSettings.Color
                },
                !loIsNil(trackPoint.Course)
            )
        );
    }

    private createLastPoint(trackSettings: TrackSettings, trackPoint: TrackPoint, index: number) {
        return new ymaps.Placemark(
            trackPoint.Coordinates,
            {
                Index: index,
                Name: "Finish",
                Color: trackSettings.Color,
                IconPath: trackSettings.IconPath,
                IconSize: 18,
                needLabel: true,
                State: trackPoint.State,
                Type: trackPoint.Type,
                Course: trackPoint.Course
            },
            this.getPlacemarkOptions(
                {
                    iconContentSize: [100, 18],
                    iconImageSize: [100, 18],
                    iconImageOffset: [-18 / 2, -18 / 2],
                    iconColor: trackSettings.Color
                },
                !loIsNil(trackPoint.Course)
            )
        );
    }

    private createTrackPoints(trackSettings: TrackSettings, trackPoints: TrackPoint[]) {
        const callback = this.onPlacemarkClick.bind(this);
        const points = trackPoints.map((el, index) => {
            const point = this.createPlacemark(el, {
                Index: index,
                Color: trackSettings.Color
            });

            if (index !== 0 && index !== trackPoints.length - 1) {
                point.events.add("click", callback);
            }

            return point;
        });

        points.splice(-1, 1);
        points.splice(0, 1);
        return points;
    }

    private createTrackLine({ Color, TrackLineWidth }: TrackSettings, coordinates: [number, number][]) {
        return new ymaps.Polyline(coordinates, undefined, {
            cursor: "default",
            opacity: 0.7,
            strokeColor: Color,
            strokeWidth: TrackLineWidth
        });
    }

    private getPlacemarkOptions(options, hasCourse) {
        return Object.assign(
            {
                iconLayout: "default#imageWithContent",
                iconContentLayout: ymaps.templateLayoutFactory.createClass(
                    hasCourse ? templates.trackArrowMarkerTemplate : templates.markerTemplate
                ),
                iconImageHref: "",
                hasBalloon: false
            },
            options
        );
    }

    private createPlacemark(point: TrackPoint, { Index, Color }) {
        let color =
            point.State === TrackPointState.Emergency
                ? "#FF4136"
                : point.State === TrackPointState.PreEmergency
                ? "#FF851B"
                : Color;
        let properties = {
            needLabel: false,
            Index,
            Color: color,
            IconSize: !loIsNil(point.Course) ? 16 : 10,
            State: point.State,
            Type: point.Type,
            Course: point.Course
        };

        return new ymaps.Placemark(
            point.Coordinates,
            properties,
            this.getPlacemarkOptions(
                {
                    iconContentSize: [properties.IconSize, properties.IconSize],
                    iconImageSize: [properties.IconSize, properties.IconSize],
                    iconImageOffset: [-properties.IconSize / 2, -properties.IconSize / 2],
                    iconColor: properties.Color
                },
                !loIsNil(point.Course)
            )
        );
    }

    private initPointsClusterer() {
        return new ymaps.Clusterer({
            clusterDisableClickZoom: true,
            hasBalloon: false,
            clusterIconLayout: "default#pieChart",
            clusterIconPieChartRadius: 16,
            // Радиус центральной части макета.
            clusterIconPieChartCoreRadius: 12,
            // Ширина линий-разделителей секторов и внешней обводки диаграммы.
            // clusterIconPieChartStrokeWidth: 1,
            maxZoom: 18
        });
    }
}
