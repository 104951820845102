import { Vue, Component, Prop } from "vue-property-decorator";
import ColorPicker from "@/components/Select/ColorPicker.vue";
import colors from "@/const/colors";
import { geometryTypes } from "@/const/enums";
import regexHolder from "@/const/string-validation-regex";
import IGeoobject from "@/contracts/IGeoobject";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";
import "./GeoobjectEditor.scss";

@Component({
    components: {
        ColorPicker
    }
})
export default class GeoobjectEditor extends Vue {
    @Prop()
    value!: IGeoobject;

    @State
    user!: IUser;

    handleChangeGeoobjectType(value) {
        this.$emit("input", { ...this.value, GeoobjectType: value });
    }

    handleChangeColor(value) {
        this.$emit("input", {
            ...this.value,
            InterfaceSettings: {
                ...this.value.InterfaceSettings,
                color: value
            }
        });
    }

    handleChangeLineWidth(value) {
        this.$emit("input", {
            ...this.value,
            InterfaceSettings: {
                ...this.value.InterfaceSettings,
                lineWidth: value
            }
        });
    }

    async save() {
        try {
            await (this.$refs.form as any).validate();
            this.$emit("save");
        } catch (error) {}
    }

    cancel() {
        this.$emit("cancel");
    }

    rules() {
        return {
            Name: [
                {
                    required: true,
                    message: this.$t("default.required"),
                    trigger: "blur"
                },
                {
                    max: 100,
                    message: this.$t("default.maxlength", { length: 100 }),
                    trigger: "blur"
                },
                {
                    pattern: regexHolder["settings_name"].value,
                    message: this.$t("default.invalid"),
                    trigger: "blur"
                }
            ],
            Comment: [
                {
                    max: 400,
                    message: this.$t("default.maxlength", { length: 400 }),
                    trigger: "blur"
                },
                {
                    pattern: regexHolder["settings_comment"].value,
                    message: this.$t("default.invalid"),
                    trigger: "blur"
                }
            ]
        };
    }

    render() {
        return (
            <transition name="fade-slide">
                <div
                    class="d-flex w-100 h-100 flex-column"
                    style="position: absolute; background-color: rgb(0, 0, 0, 0.5); border-radius: 4px; box-shadow: 0 6px 12px 0 rgba(0,0,0,0.10); z-index: 4"
                >
                    <div
                        class="w-100 p-3"
                        style="background-color: #fff; border-radius: 4px; box-shadow: 0 6px 12px 0 rgba(0,0,0,0.10);"
                    >
                        <el-form
                            {...{ attrs: { model: this.value } }}
                            rules={this.rules()}
                            show-message={false}
                            status-icon
                            class="w-100"
                            ref="form"
                        >
                            <el-form-item label={this.$t("default.name")} class="lo-form-item" prop="Name">
                                <el-input size="small" vModel={this.value.Name}></el-input>
                            </el-form-item>
                            <el-form-item label={this.$t("default.comment")} class="lo-form-item" prop="Comment">
                                <el-input size="small" type="textarea" vModel={this.value.Comment}></el-input>
                            </el-form-item>
                            {!~this.value.Id && (
                                <el-form-item
                                    label={this.$t("geoobjects.type")}
                                    class="lo-form-item"
                                    prop="GeoobjectType"
                                >
                                    <el-select
                                        value={this.value.GeoobjectType}
                                        onChange={this.handleChangeGeoobjectType}
                                        size="small"
                                    >
                                        {geometryTypes.map(item => (
                                            <el-option
                                                key={item.key}
                                                label={this.$t("enums.geometrytypes." + item.key)}
                                                value={item.value}
                                            ></el-option>
                                        ))}
                                    </el-select>
                                </el-form-item>
                            )}

                            <el-form-item
                                label={this.$t("default.color")}
                                class="lo-form-item"
                                prop="InterfaceSettings.color"
                            >
                                <color-picker
                                    items={colors}
                                    value={this.value.InterfaceSettings.color}
                                    onInput={this.handleChangeColor}
                                ></color-picker>
                            </el-form-item>
                            {this.user.BnwasManagement && this.value.GeoobjectType !== 1 && (
                                <el-form-item class="lo-form-item text-left" prop="IsExcludeForBNWAS">
                                    <el-checkbox vModel={this.value.IsExcludeForBNWAS}>
                                        Геозона принудительно отключающая мониторинг статуса СКДВП
                                    </el-checkbox>
                                </el-form-item>
                            )}
                            {this.value.GeoobjectType !== 1 && (
                                <el-form-item
                                    label={this.$t("geoobjects.linewidth")}
                                    class="lo-form-item"
                                    prop="InterfaceSettings.lineWidth"
                                >
                                    <el-select
                                        value={this.value.InterfaceSettings.lineWidth}
                                        onChange={this.handleChangeLineWidth}
                                        placeholder="Select"
                                        size="small"
                                    >
                                        {[1, 2, 3, 4, 5].map(item => (
                                            <el-option key={item} label={item + "px"} value={item}></el-option>
                                        ))}
                                    </el-select>
                                </el-form-item>
                            )}
                        </el-form>
                        <div class="lo-form-item d-flex justify-content-end">
                            <el-button onClick={this.cancel} size="small">
                                {this.$t("default.cancel")}
                            </el-button>
                            <el-button
                                disabled={!this.user.CanEditGeoobjects}
                                onClick={this.save}
                                size="small"
                                type="primary"
                            >
                                {this.$t("default.save")}
                            </el-button>
                        </div>
                    </div>
                </div>
            </transition>
        );
    }
}
