import YMap from "@/components/Map/yandex/YMap";
import Track, { TrackSettings } from "@/components/Map/yandex/Track";
import HistoryMarker from "@/components/Map/yandex/HistoryMarker";

import YandexMap from "@/components/Map/yandex/YandexMap";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import MapPointModal from "@/components/Monitoring/MapPointModal";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import { TrackPoint } from "@/api/monitoring";
import ResizeObserver from "resize-observer-polyfill";

export interface PointClickEvent {
    Timestamp: string;
    Latitude: number;
    Longitude: number;
}

let mapInstance: YandexMap | undefined;
let trackInstance: Track | undefined;
let markerInstance: HistoryMarker | undefined;

@Component({
    components: {
        YMap,
        MapPointModal
    }
})
export default class HistoryMap extends Vue {
    @Prop()
    private trackPoint;

    @Prop()
    private trackData;

    @Prop()
    private beacon!: IBeaconMonitoring;

    @Watch("trackData")
    onTrackDataChanged(track) {
        this.showTrack(track);
    }

    @Watch("trackPoint")
    onTrackPointChanged(point) {
        this.showPoint(point);
    }

    @State
    mapOptions;

    @State
    decoratorOptions;

    @State
    maxMarkerVisibilityZoom;

    private resizeObserver: ResizeObserver | null = null;
    private initMap() {
        mapInstance = new YandexMap("history-map", { ...this.mapOptions });
        this.resizeObserver = new ResizeObserver((entries, observer) => {
            if (mapInstance) {
                mapInstance.fitToViewport();
            }
        });

        this.resizeObserver.observe(this.$refs.historyMap as Element);
    }

    public beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    private showTrackPointModal(trackSettings: TrackSettings, trackPoints: TrackPoint[]): void {
        if (trackSettings && trackPoints) {
            this.$emit("pointClick", {
                Timestamp: trackPoints[0].Timestamp,
                Latitude: trackPoints[0].Coordinates[0],
                Longitude: trackPoints[0].Coordinates[1]
            });
        }
    }

    private showTrack(data) {
        if (trackInstance) {
            trackInstance.hide();
        }

        let track: TrackSettings = {
            Id: this.beacon.Id,
            StartDt: new Date(),
            BeaconId: this.beacon.Id,
            EndDt: new Date(),
            BeaconName: this.beacon.Name,
            LastCoordinateDt: this.beacon.Location ? this.beacon.Location.Timestamp : undefined,
            Color: this.beacon.UserInterfaceSettings.Color,
            IconPath: this.beacon.IconPath,
            TrackLineWidth: this.beacon.UserInterfaceSettings.TrackLineWidth
        };

        if (mapInstance) {
            trackInstance = new Track(mapInstance, track, data, this.showTrackPointModal);
            trackInstance.show();
            trackInstance.focus();
        }
    }

    private showPoint(point) {
        if (markerInstance) {
            markerInstance.hide();
        }

        if (point) {
            const coordinates = [point.Latitude, point.Longitude];
            markerInstance = new HistoryMarker(mapInstance, coordinates, this.beacon.UserInterfaceSettings.Color);
            markerInstance.show();
            markerInstance.focusAndZoom();
        }
    }

    public render() {
        return (
            <div id="history-map" class="history-map" ref="historyMap">
                <y-map onScriptIsLoaded={this.initMap} />
            </div>
        );
    }
}
