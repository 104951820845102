import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";

import sorting from "@/components/Monitoring/MonitoringWidget/Sorting";
import regexHolder from "@/const/string-validation-regex";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class GroupEditor extends Vue {
    @Prop()
    visibility!: boolean;

    @Prop()
    group;

    form = cloneDeep(this.group);

    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    get sortedBeacons() {
        return sorting[0].sortFunction(this.beacons);
    }

    handleRowClick(row) {
        const table = this.$refs.table as any;
        table.toggleRowSelection(row);
    }

    handleSelectionChange(val: IBeaconMonitoring[]) {
        this.form.BeaconIds = val.map(val => val.Id);
    }

    toggleSelection() {
        const table = this.$refs.table as any;
        table.clearSelection();
        for (const beacon of this.sortedBeacons) {
            if (this.group.BeaconIds.includes(beacon.Id)) {
                table.toggleRowSelection(beacon, true);
            }
        }
    }

    async save() {
        try {
            await (this.$refs.form as any).validate();
            if (this.group.Id) {
                this.$emit("edit", this.form);
            } else {
                this.$emit("create", this.form);
            }
        } catch (error) {}
    }

    mounted() {
        this.$nextTick(() => {
            this.toggleSelection();
        });
    }

    render() {
        return (
            <el-dialog
                close-on-click-modal={false}
                title={this.$t("groupseditor.editgroup")}
                visible={this.visibility}
                onClose={() => this.$emit("visibilityChange", false)}
                append-to-body={true}
                modal-append-to-body={true}
                width="500px"
            >
                <el-form {...{ attrs: { model: this.form } }} ref="form">
                    <el-form-item
                        label={this.$t("default.name")}
                        rules={[
                            {
                                required: true,
                                message: this.$t("default.required"),
                                trigger: "blur"
                            },
                            {
                                max: 100,
                                message: this.$t("default.maxlength", { length: 100 }),
                                trigger: "blur"
                            },
                            {
                                pattern: regexHolder["settings_comment"].value,
                                message: this.$t("default.invalid"),
                                trigger: "blur"
                            }
                        ]}
                        class="mb-3"
                        prop="Name"
                    >
                        <el-input size="small" vModel={this.form.Name} />
                    </el-form-item>
                </el-form>
                <label class="el-form-item__label">{this.$t("default.beacons")}</label>
                <div class="mb-3">
                    <el-table
                        data={this.sortedBeacons}
                        empty-text={this.$t("default.nodata")}
                        on-selection-change={this.handleSelectionChange}
                        on-row-click={this.handleRowClick}
                        height="350px"
                        ref="table"
                        row-key="Id"
                    >
                        <el-table-column reserve-selection={true} type="selection" width="55" />
                        <el-table-column
                            label={this.$t("default.object")}
                            property="Name"
                            {...{
                                scopedSlots: {
                                    default: (scope: { row: IBeaconMonitoring }) => {
                                        return (
                                            <div class="d-flex align-items-center">
                                                <div
                                                    style={{
                                                        backgroundColor: `${scope.row.UserInterfaceSettings.Color}22`
                                                    }}
                                                    class="rounded-circle mr-2"
                                                >
                                                    <icon-base
                                                        iconHeight={24}
                                                        iconWidth={24}
                                                        iconName={scope.row.IconPath}
                                                        iconColor={scope.row.UserInterfaceSettings.Color}
                                                    />
                                                </div>
                                                <div>{scope.row.Name}</div>
                                            </div>
                                        );
                                    }
                                }
                            }}
                        />
                    </el-table>
                </div>
                <div slot="footer" class="d-flex justify-content-end">
                    <el-button size="small" onClick={() => this.$emit("visibilityChange", false)}>
                        {this.$t("default.cancel")}
                    </el-button>
                    <el-button size="small" onClick={this.save} type="primary">
                        {this.$t("default.save")}
                    </el-button>
                </div>
            </el-dialog>
        );
    }
}
