import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import ChangePassword from "@/components/UserProfile/ChangePassword";
import UserSettings from "@/components/UserProfile/UserSettings";
import UserInfo from "@/components/UserProfile/UserInfo";
import "./UserProfileView.scss";
import IUser from "@/contracts/IUser";
import Panel from "@/components/Containers/Panel";
import ReportCard from "@/components/Reports/ReportCard";
import { loadTransactions } from "@/api/account";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";

enum Transactiontype {
    None,
    ActivationIridium,
    ActivationGSM,
    AbonentPaymentIridium,
    AbonentPaymentGSM,
    PartialAbonentPaymentIridium,
    PartialAbonentPaymentGSM,
    RemoveDeactivationIridium,
    RemoveDeactivationGSM,
    RemoveSuspensionIridium,
    RemoveSuspensionGSM,
    DeactivationIridium,
    DeactivationGSM,
    TrafficOverflowIridium,
    TrafficOverflowGSM,
    SuspendIridium,
    SuspendGSM,
    RefillAccount,
    AbonentPaymentReturnGSM,
    AbonentPaymentReturnIridium
}

@Component({
    components: {
        ChangePassword,
        UserSettings,
        UserInfo,
        Panel,
        ReportCard
    }
})
export default class UserProfileView extends Vue {
    @State
    private user!: IUser;

    // private transactions: any[] = [];

    // async created() {
    //     this.transactions = await loadTransactions();
    // }

    // @State("beacons", { namespace: "monitoring" })
    // beacons!: IBeaconMonitoring[];

    // private getBeaconName(transaction) {
    //     let beacon = this.beacons.find(beacon => beacon.Id === transaction.BeaconId);
    //     if (beacon) {
    //         return beacon.Name;
    //     }
    //     return "";
    // }

    render() {
        if (!this.user) {
            return null;
        }
        return (
            <div class="canvas">
                <div class="page-view">
                    <div class="container-fluid user-profile-view__body scroll h-100">
                        <div class="row m-0 justify-content-md-center">
                            <div class="p-0 col-md-12 col-lg-6 col-xl-4">
                                <panel>
                                    <div class="mb-2 d-flex flex-column align-items-center ">
                                        <div
                                            style={{
                                                backgroundColor: `#1070ca10`,
                                                color: "#1070ca",
                                                width: "54px",
                                                height: "54px"
                                            }}
                                            class="rounded-circle mb-2 d-flex align-items-center justify-content-center"
                                        >
                                            <i class="fad fa-lg fa-user" />
                                        </div>
                                        <h4 class="mb-1">{this.user.UserName}</h4>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div class="mr-3 font-weight-medium">
                                            {this.$t("profile.balance", { balance: this.user.Balance })}
                                        </div>
                                        <el-button
                                            plain
                                            size="small"
                                            onClick={() => this.$router.push({ name: "payment" })}
                                            type="primary"
                                        >
                                            {this.$t("profile.makepayment")}
                                        </el-button>
                                    </div>
                                </panel>
                                <panel>
                                    <el-tabs>
                                        <el-tab-pane label={this.$t("default.settings")}>
                                            <user-settings />
                                        </el-tab-pane>
                                        <el-tab-pane label={this.$t("profile.password")}>
                                            <change-password />
                                        </el-tab-pane>
                                        <el-tab-pane label={this.$t("profile.personalinfo")}>
                                            <user-info />
                                        </el-tab-pane>
                                    </el-tabs>
                                </panel>
                            </div>
                            {/* <div class="p-0 col-md-12 col-lg-8 col-xl-5">
                                <panel header="Последние транзакции">
                                    {this.transactions.length <= 0 ? (
                                        <div class="text-muted">Нет транзакций</div>
                                    ) : null}
                                    <table class="text-left w-100">
                                        {this.transactions.map(transaction => (
                                            <tr>
                                                <td class="font-weight-medium p-1">
                                                    <date-wrapper
                                                        date={transaction.ProcessedDt}
                                                        format="DD-MMM-YY, HH:mm"
                                                    />
                                                </td>
                                                <td class="p-1">
                                                    {this.$t(
                                                        `TransactionType.${
                                                            Transactiontype[transaction.TransactionType]
                                                        }`
                                                    )}
                                                </td>
                                                <td class="p-1">
                                                    {transaction.BeaconId && this.getBeaconName(transaction)}
                                                </td>
                                                <td
                                                    class={[
                                                        "font-weight-medium p-1",
                                                        transaction.Cost > 0 && "color-glyph-normal",
                                                        transaction.Cost < 0 && "color-glyph-emergency"
                                                    ]}
                                                >
                                                    {transaction.Cost.toFixed(2)}
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </panel>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
