import { Component, Mixins } from "vue-property-decorator";

import { updateInfo, loadInfo } from "@/api/account";

import messages from "@/components/Mixins/messages";

import regexHolder from "@/const/string-validation-regex";

import "./UserInfo.scss";

@Component
export default class UserInfo extends Mixins(messages) {
    get user() {
        return this.$store.state.user;
    }

    working: boolean = false;
    countries: any[] = [];
    form: any = null;

    async saveInfo() {
        try {
            let form: any = this.$refs["form"];
            await form.validate();

            this.working = true;
            await updateInfo(this.form);
            this.operationSuccess();
        } catch (error) {
        } finally {
            setTimeout(() => {
                this.working = false;
            }, 1000);
        }
    }

    async loadInfo() {
        let info = await loadInfo();
        if (info) {
            this.form = { ...info.data };
        }
    }

    rules() {
        return {
            Name: [
                {
                    max: 150,
                    message: this.$t("default.maxlength", { length: 150 }),
                    trigger: "blur"
                },
                {
                    pattern: regexHolder["settings_name"].value,
                    message: this.$t("default.invalid"),
                    trigger: "blur"
                }
            ],
            Phone: [
                {
                    max: 20,
                    message: this.$t("default.maxlength", { length: 20 }),
                    trigger: "blur"
                },
                {
                    pattern: regexHolder["settings_phone"].value,
                    message: this.$t("default.invalidPhone"),
                    trigger: "blur"
                }
            ],
            Organization: [
                {
                    max: 150,
                    message: this.$t("default.maxlength", { length: 150 }),
                    trigger: "blur"
                },
                {
                    pattern: regexHolder["settings_organization"].value,
                    message: this.$t("default.invalid"),
                    trigger: "blur"
                }
            ]
        };
    }

    mounted() {
        let countries = require("i18n-iso-countries");
        countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));
        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        let countriesRaw = countries.getNames(localStorage.getItem("locale"));
        this.countries = Object.keys(countriesRaw).map(i => {
            return {
                label: countriesRaw[i],
                value: i
            };
        });

        this.loadInfo();
    }

    render() {
        let countries = this.countries.map(country => (
            <el-option key={country.value} label={country.label} value={country.value} />
        ));

        return this.form ? (
            <el-form
                rules={this.rules()}
                ref="form"
                attrs={{ model: this.form }}
                disabled={!this.user.CanEditPersonalInfo}
            >
                <el-form-item>
                    <el-radio-group vModel={this.form.LegalEntity} size="small" class="d-flex w-100 lo-radio-group">
                        <el-radio-button class="flex-grow-1" style="flex-basis: 0" label={true}>
                            {this.$t("profile.enterprise")}
                        </el-radio-button>
                        <el-radio-button class="flex-grow-1" style="flex-basis: 0" label={false}>
                            {this.$t("profile.individual")}
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label={this.$t("profile.name")} prop="Name">
                    <el-input size="small" vModel={this.form.Name} maxlength={150} />
                </el-form-item>
                <el-form-item label={this.$t("profile.phone")} prop="Phone">
                    <el-input size="small" vModel={this.form.Phone} maxlength={20} />
                </el-form-item>
                <el-form-item label={this.$t("profile.organization")} prop="Organization">
                    <el-input size="small" vModel={this.form.Organization} maxlength={150} />
                </el-form-item>
                <el-form-item label={this.$t("profile.country")} prop="Country">
                    <el-select
                        filterable
                        size="small"
                        vModel={this.form.Country}
                        placeholder={this.$t("profile.country")}
                    >
                        {countries}
                    </el-select>
                </el-form-item>
                <el-form-item class="m-0 d-flex justify-content-around">
                    <el-button size="small" loading={this.working} onClick={this.saveInfo} type="primary">
                        {this.$t("default.save")}
                    </el-button>
                </el-form-item>
            </el-form>
        ) : null;
    }
}
