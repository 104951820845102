import YMap from "@/components/Map/yandex/YMap";
import YandexMap from "@/components/Map/yandex/YandexMap";

import { createMapStore, findBeaconMapLayers, createBeaconMapLayers, MapStore } from "@/components/Mixins/mapdata";
import GeoobjectsMixin from "@/components/Mixins/yGeoobjects";

import loDifferenceBy from "lodash/differenceBy";

import MapPointModal from "@/components/Monitoring/MapPointModal";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { State, Getter } from "vuex-class";
import Track, { TrackSettings } from "../Map/yandex/Track";
import { trackData } from "@/store/modules/tracks";
import { TrackPoint } from "@/api/monitoring";

const mapStore: MapStore = createMapStore();

@Component({
    components: {
        YMap,
        MapPointModal
    }
})
export default class TracksMap extends Mixins(GeoobjectsMixin as any) {
    private trackPoints: TrackPoint[] = [];
    private mapPointModalVisibility: boolean = false;
    private currentTrackSettings: TrackSettings | undefined = undefined;

    showTrackPointModal(trackSettings: TrackSettings, trackPoints: TrackPoint[]): void {
        if (trackSettings && trackPoints) {
            this.currentTrackSettings = trackSettings;
            this.trackPoints = trackPoints;
            this.mapPointModalVisibility = true;
        }
    }

    @State
    decoratorOptions;

    @State
    maxMarkerVisibilityZoom;

    showTrack(trackSettingsArray: TrackSettings[]) {
        const { Map } = mapStore;
        if (!Map) {
            return;
        }

        let layersToFocus: Track[] = [];
        for (const trackSettings of trackSettingsArray) {
            let beaconMapLayers = findBeaconMapLayers(mapStore, trackSettings.Id);
            if (!beaconMapLayers) {
                beaconMapLayers = createBeaconMapLayers(mapStore, trackSettings.Id);
            }

            const trackDataTemp = trackData.find(x => x.Id === trackSettings.Id);
            if (trackDataTemp) {
                const newTrack = new Track(Map, trackSettings, trackDataTemp, this.showTrackPointModal);
                newTrack.show();

                beaconMapLayers.Track = newTrack;

                layersToFocus.push(newTrack);
            }
        }
        if (layersToFocus.length > 0) {
            Map.focusToLayers(layersToFocus);
        }
    }

    private hideTrack(trackSettingsArray: TrackSettings[]) {
        for (const trackSettings of trackSettingsArray) {
            let beaconMapLayers = findBeaconMapLayers(mapStore, trackSettings.Id);
            if (beaconMapLayers && beaconMapLayers.Track) {
                beaconMapLayers.Track.hide();
                beaconMapLayers.Track = undefined;
            }
        }
    }

    @State("mapOptions")
    mapOptions;

    @State("tracks", { namespace: "tracks" })
    tracks;

    @Getter("tracksSelectedGeoobjects", { namespace: "geoobjects" })
    selectedGeoobjects;

    @Watch("tracks")
    onTracksChanged(tracks: TrackSettings[], oldTracks: TrackSettings[]) {
        let tracksToShow = loDifferenceBy(tracks, oldTracks, "Id");
        this.showTrack(tracksToShow);

        let tracksToDelete = loDifferenceBy(oldTracks, tracks, "Id");
        this.deleteTrack(tracksToDelete);
    }

    initMap() {
        mapStore.Map = new YandexMap("tracks-map", { ...this.mapOptions });
    }

    deleteTrack(tracks: TrackSettings[]) {
        this.hideTrack(tracks);
        mapStore.BeaconMapLayers = mapStore.BeaconMapLayers.filter(el => !tracks.some(track => el.Id === track.Id));
    }

    activated() {
        this.$nextTick(() => {
            const { Map } = mapStore;
            if (Map) {
                Map.fitToViewport();
            }
        });
    }

    handleVisibilityChange(event: boolean) {
        this.mapPointModalVisibility = event;
    }

    public render() {
        return (
            <div id="tracks-map" class="h-100 w-100 monitoring-map" style="z-index: 0">
                {this.mapPointModalVisibility ? (
                    <map-point-modal
                        trackData={this.currentTrackSettings}
                        trackPointsProperties={this.trackPoints}
                        visibility={this.mapPointModalVisibility}
                        onVisibilityChange={this.handleVisibilityChange}
                    />
                ) : null}
                <y-map onScriptIsLoaded={this.initMap} />
            </div>
        );
    }
}
