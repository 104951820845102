import loOrderBy from "lodash/orderBy";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
export default [
    {
        value: 0,
        key: "nameaz",
        sortFunction: (array: IBeaconMonitoring[]) => loOrderBy<IBeaconMonitoring>(array, ["Name"], ["asc"])
    },
    {
        value: 1,
        key: "nameza",
        sortFunction: (array: IBeaconMonitoring[]) => loOrderBy<IBeaconMonitoring>(array, ["Name"], ["desc"])
    },
    {
        value: 2,
        key: "dateasc",
        sortFunction: (array: IBeaconMonitoring[]) =>
            loOrderBy<IBeaconMonitoring>(array, [({ ConnectionDt }) => new Date(ConnectionDt || "0")], ["asc"])
    },
    {
        value: 3,
        key: "datedesc",
        sortFunction: (array: IBeaconMonitoring[]) =>
            loOrderBy<IBeaconMonitoring>(array, [({ ConnectionDt }) => new Date(ConnectionDt || "0")], ["desc"])
    }
];
