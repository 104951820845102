import YMap from "@/components/Map/yandex/YMap";
import YandexMap from "@/components/Map/yandex/YandexMap";
import Geoobject from "@/components/Map/yandex/Geoobject";

import { geometryTypes } from "@/const/enums";

import { State } from "vuex-class";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import IGeoobject from "@/contracts/IGeoobject";

import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";

@Component({
    components: {
        YMap
    }
})
export default class GeoobjectsYMap extends Vue {
    map!: YandexMap;
    geoobjectLayer: Geoobject | null = null;
    geometryTypes = geometryTypes;

    @Prop() editable!: boolean;
    @Prop() geoobject!: IGeoobject;
    @State("mapOptions") mapOptions!: any;

    @Watch("geoobject", { deep: true })
    onGeoobjectChanged(value: IGeoobject, oldValue: IGeoobject) {
        if (value) {
            if (
                !oldValue ||
                !isEqual(value.Geometry, oldValue.Geometry) ||
                value.GeoobjectType !== oldValue.GeoobjectType ||
                value.Id !== oldValue.Id
            ) {
                this.deleteGeoobject();
                this.drawGeoobject(value);
            } else {
                this.updateGeoobject(value, oldValue);
            }
        } else {
            this.deleteGeoobject();
        }
    }

    @Watch("editable")
    onEditableChanged(value: boolean) {
        if (this.geoobjectLayer) {
            if (value) {
                this.geoobjectLayer.startEditing(this.updateGeometry);
            } else {
                this.geoobjectLayer.stopEditing(this.updateGeometry);
            }
        }
    }

    initMap() {
        this.map = new YandexMap("geoobjects-map", { ...this.mapOptions });
    }

    updateGeoobject(geoobject: IGeoobject, oldGeoobject: IGeoobject) {
        if (isNil(this.geoobjectLayer)) {
            return;
        }

        if (geoobject.InterfaceSettings.color !== oldGeoobject.InterfaceSettings.color) {
            this.geoobjectLayer.changeColor(geoobject.InterfaceSettings.color);
        } else if (geoobject.InterfaceSettings.lineWidth !== oldGeoobject.InterfaceSettings.lineWidth) {
            this.geoobjectLayer.changeStrokeWidth(geoobject.InterfaceSettings.lineWidth);
        }
    }

    deleteGeoobject(): void {
        if (this.geoobjectLayer) {
            this.geoobjectLayer.hide();
            this.geoobjectLayer = null;
        }
    }

    drawGeoobject(geoobject: IGeoobject): void {
        this.geoobjectLayer = new Geoobject(this.map, geoobject);
        this.geoobjectLayer.show();

        this.geoobjectLayer.focus();
        if (this.editable) {
            this.geoobjectLayer.startEditing(this.updateGeometry);
        }
    }

    updateGeometry(): void {
        if (isNil(this.geoobjectLayer)) {
            return;
        }

        let geometry = this.geoobjectLayer.getGeometry();
        if (geometry) {
            this.$emit("updateGeometry", geometry);
        }
    }

    activated() {
        this.$nextTick(() => {
            if (this.map) {
                this.map.fitToViewport();
            }
        });
    }

    render() {
        return (
            <div id="geoobjects-map" style="z-index: 0">
                <y-map onScriptIsLoaded={() => this.initMap()} />
            </div>
        );
    }
}
