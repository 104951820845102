import HistoryMap, { PointClickEvent } from "@/components/History/HistoryMap";
import Chart from "@/components/Monitoring/Chart";
import { loadHistoryChart, Track } from "@/api/monitoring";

import "./HistoryView.scss";

import { Component, Vue } from "vue-property-decorator";

import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import ChartData from "@/contracts/IChartData";
import TimelinePanel from "@/components/History/TimelinePanel";
import SideContainer from "@/components/History/SideContainer";
import HistoryPanel, { HistoryForm } from "@/components/History/HistoryPanel";
import TimelineElement, { LocationData } from "@/api/models/Timeline";
import { loadTimeline } from "@/api/timeline";

@Component({
    name: "HistoryView",
    components: {
        HistoryMap,
        Chart,
        HistoryPanel,
        TimelinePanel,
        SideContainer
    }
})
export default class HistoryView extends Vue {
    private timelineElements: TimelineElement[] = [];
    public render() {
        return (
            <div class="canvas">
                <div class="HistoryViewContainer">
                    <history-panel
                        onPanelStateChanged={this.onPanelStateChanged}
                        onLocationClick={this.handlePointClick}
                        timestamp={this.timestamp}
                        timelineElements={this.timelineElements}
                        loading={this.isWorking}
                        onSubmitForm={this.onSubmitForm}
                        onBackToSettings={this.clearData}
                    />
                    <div class="HistoryViewMap">
                        <history-map
                            beacon={this.currentBeacon}
                            trackData={this.trackData}
                            trackPoint={this.trackPoint}
                            onPointClick={this.handlePointClick}
                            class="h-100 w-100 flex-grow-1 flex-shrink-1"
                        />
                    </div>
                    <side-container sidebarOpen={this.historyPanelOpen}>
                        <div class="HistoryViewChart">
                            <chart
                                timestamp={this.timestamp}
                                chartData={this.chartData}
                                onChartClick={this.handlePointClick}
                                class="h-100"
                                ref="chartContainer"
                                clickEvent={true}
                            />
                        </div>
                    </side-container>
                </div>
            </div>
        );
    }

    private clearData() {
        this.timelineElements = [];
        this.trackPoint = null;
        this.chartData = null;
        this.trackData = null;
    }

    private onPanelStateChanged(state) {
        this.historyPanelOpen = state;
    }

    private async onSubmitForm(form: HistoryForm, beacon: IBeaconMonitoring) {
        if (form.datetimerange && form.beaconId) {
            try {
                this.isWorking = true;
                this.trackPoint = null;

                let timelineElements = await loadTimeline(
                    form.beaconId,
                    form.datetimerange[0],
                    form.datetimerange[1],
                    form.sIds
                );

                let [startDt, endDt] = form.datetimerange;
                if (!form.beaconId) {
                    return;
                }
                let chartData = await loadHistoryChart(form.beaconId, startDt, endDt);
                if (chartData && this.isDataExists(chartData) && timelineElements.length > 0) {
                    this.chartData = chartData;
                    this.trackData = chartData.Track;
                    this.timelineElements = timelineElements;
                }
                this.currentBeacon = beacon;
            } catch (error) {
            } finally {
                this.isWorking = false;
            }
        }
    }

    private currentBeacon: IBeaconMonitoring | null = null;
    isResizing: boolean = false;
    isWorking: boolean = false;
    trackPoint: LocationData | null = null;
    trackData: Track | null = null;
    chartData: ChartData | null = null;
    pickerOptions: any = {};
    historyPanelOpen: boolean = true;

    private timestamp: string | Date | null = null;

    private handlePointClick({ Timestamp, ...Coordinates }: PointClickEvent) {
        this.trackPoint = Coordinates;
        this.timestamp = Timestamp;
    }

    isDataExists(chartData: ChartData | null) {
        if (!chartData || !chartData.Track || chartData.Track.Coordinates.length < 2) {
            this.$alert(this.$t("tracks.nodata").toString(), this.$t("default.nodata").toString(), {
                showClose: false,
                type: "warning",
                confirmButtonText: "ОК"
            });
            return false;
        }
        return true;
    }
}
