import { Vue, Prop, Component } from "vue-property-decorator";
import { geometryTypes } from "@/const/enums";
import GeoobjectCard from "./GeoobjectCard";
import sortingTypes from "./sorting";
import IGeoobject from "@/contracts/IGeoobject";
import { State } from "vuex-class";
import IUser from "@/contracts/IUser";

@Component({
    components: {
        GeoobjectCard
    }
})
export default class GeoobjectsPanel extends Vue {
    @Prop()
    selected!: IGeoobject;

    @Prop()
    geoobjects!: IGeoobject[];

    @State
    user!: IUser;

    sorting = sortingTypes[0];
    geoobjectTypesFilter = geometryTypes.map(x => x.value);
    filter: string = "";

    handleSelectGeoobject(geoobject) {
        this.$emit("select", geoobject);
    }

    handleCreateGeoobject() {
        this.$emit("create");
    }

    handleEditGeoobject(geoobject) {
        this.$emit("edit", geoobject);
    }

    handleDeleteGeoobject(geoobject) {
        this.$confirm(
            this.$t("default.confirmDelete", {
                name: geoobject.Name
            }).toString(),
            "",
            {
                confirmButtonText: this.$t("default.ok").toString(),
                cancelButtonText: this.$t("default.cancel").toString(),
                type: "warning",
                showClose: false,
                center: true
            }
        )
            .then(() => {
                this.$emit("delete", geoobject);
            })
            .catch(() => {});
    }

    handleSortCommand(sorting) {
        this.sorting = sorting;
    }

    get style() {
        return {
            maxHeight: this.$store.state.canvasSize.h - 30 + "px"
        };
    }

    get filteredGeoobjects() {
        let geoobjects = this.geoobjects.filter(x => {
            let typeFilter = this.geoobjectTypesFilter.includes(x.GeoobjectType);

            if (this.filter) {
                return typeFilter && x.Name.toLowerCase().includes(this.filter.toLowerCase());
            }
            return typeFilter;
        });
        return this.sorting.sortFunction(geoobjects);
    }

    render() {
        return (
            <div class="d-flex flex-column h-100" style={this.style}>
                <div class="px-3 py-3 panel-header-overlay" style="border-radius: 4px 4px 0 0">
                    <div class="mb-3 d-flex">
                        <div class="mr-3 flex-grow-1">
                            <el-input
                                size="small"
                                vModel={this.filter}
                                clearable
                                placeholder={this.$t("default.search")}
                                suffix-icon="el-icon-search"
                            >
                                <el-dropdown
                                    size="small"
                                    slot="append"
                                    trigger="click"
                                    onCommand={this.handleSortCommand}
                                >
                                    <el-button icon="fad fa-sort-amount-down fa-lg" size="small"></el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        {sortingTypes.map(sort => (
                                            <el-dropdown-item key={sort.value} command={sort}>
                                                {this.$t("default.sorting." + sort.key)}
                                            </el-dropdown-item>
                                        ))}
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-input>
                        </div>
                        <el-button
                            type="primary"
                            onClick={this.handleCreateGeoobject}
                            icon="fas fa-plus"
                            size="small"
                        ></el-button>
                    </div>
                </div>
                <div class="scroll d-flex flex-column p-2">
                    {this.filteredGeoobjects.length === 0 && (
                        <span class="text-muted p-4">{this.$t("geoobjects.nogeoobjects")}</span>
                    )}

                    {this.filteredGeoobjects.map(geoobject => (
                        <geoobject-card
                            isEditable={this.user.CanEditGeoobjects}
                            geometryTypes={geometryTypes}
                            key={geoobject.Id}
                            geoobject={geoobject}
                            isSelected={this.selected && geoobject.Id === this.selected.Id}
                            onSelectGeoobject={this.handleSelectGeoobject}
                            onDeleteGeoobject={this.handleDeleteGeoobject}
                            onEditGeoobject={this.handleEditGeoobject}
                        ></geoobject-card>
                    ))}
                </div>
            </div>
        );
    }
}
