import { Vue, Component } from "vue-property-decorator";
import { loadBnwasReport, BnwasReport } from "@/api/report";
import BnwasReportRow from "@/components/BnwasReport/BnwasReportRow";
import BnwasReportPanel, { BnwasReportForm } from "@/components/BnwasReport/BnwasReportPanel";

import "./BnwasReportView.scss";

@Component({
    components: {
        BnwasReportRow,
        BnwasReportPanel
    }
})
export default class BnwasReportView extends Vue {
    private bnwasReports: BnwasReport[] = [];
    private loading: boolean = false;

    private async submitForm(form: BnwasReportForm) {
        this.loading = true;
        this.bnwasReports = await loadBnwasReport(
            form.ids,
            form.datetimerange[0],
            form.datetimerange[1],
            form.minSpeed,
            form.minDistance
        );
        this.loading = false;
    }

    public render() {
        return (
            <div class="canvas d-flex">
                <bnwas-report-panel loading={this.loading} onSubmitForm={this.submitForm}></bnwas-report-panel>
                {this.bnwasReports.length > 0 && (
                    <div class="BnwasReportTable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="p-3">№</th>
                                    <th class="p-3">{this.$t("default.BNWASReportName")}</th>
                                    <th class="text-right p-3">{this.$t("default.BNWASDistance")}</th>
                                    <th class="text-right p-3">{this.$t("default.BNWASRunningTime")}</th>
                                    <th class="text-right p-3">{this.$t("default.BNWASStatusNormalTime")}</th>
                                    <th class="text-right p-3">{this.$t("default.BNWASStatusNormalPercentage")}</th>
                                    <th class="text-right p-3">{this.$t("default.BNWASAlarmStatusTime")}</th>
                                    <th class="text-right p-3">{this.$t("default.BNWASAlarmStatusPercentage")}</th>
                                </tr>
                            </thead>
                            {this.bnwasReports.map(bnwasReport => (
                                <bnwas-report-row bnwasReport={bnwasReport}></bnwas-report-row>
                            ))}
                        </table>
                    </div>
                )}
            </div>
        );
    }
}
