import http from "./http";
import TimelineElement from "./models/Timeline";

export async function loadTimeline(
    beaconId: number,
    startDt: Date,
    endDt: Date,
    sIds?: number[]
): Promise<TimelineElement[]> {
    let params = new URLSearchParams();
    if (sIds && sIds.length > 0) {
        sIds.forEach(value => {
            params.append("sIds", value.toString());
        });
    }
    params.append("startDt", startDt.toJSON());
    params.append("endDt", endDt.toJSON());

    try {
        let { data } = await http.get<TimelineElement[]>(`/beacons.getTimeline/${beaconId}`, {
            params: params
        });
        return data;
    } catch (error) {
        // store.commit(ERROR, handleError(error));
        throw error;
    }
}
