import { Vue, Component, Prop } from "vue-property-decorator";

import "./TrackCard.scss";

@Component
export default class TrackCard extends Vue {
    @Prop() track: any;
    @Prop() selected!: boolean;

    handleDelete(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.$emit("deleteTrack", this.track);
    }

    handleToggleTrack() {
        this.$emit("toggleTrack", this.track, !this.selected);
    }

    render() {
        return (
            <div
                onClick={this.handleToggleTrack}
                style="flex: 0 0 auto;"
                class={[this.selected ? "selected" : null, "track-card d-flex flex-column mb-2 p-2"]}
            >
                <div class="lo-card_title">
                    <div class="d-flex align-items-center" style="min-width: 0">
                        <div
                            style={{
                                backgroundColor: `${this.track.Color}22`,
                                width: "36px",
                                height: "36px"
                            }}
                            class="rounded-circle mr-2 d-flex justify-content-center align-items-center"
                        >
                            <i class="fas fa-route" style={{ color: this.track.Color }} />
                        </div>
                        <div class="text-truncate font-size-s font-weight-medium">{this.track.BeaconName}</div>
                    </div>
                    <div class="d-flex">
                        <el-button
                            icon="fa fa-trash"
                            class="lo-card-button track-card__button"
                            onClick={this.handleDelete}
                        />
                    </div>
                </div>
                <div class="lo-card_description text-muted">
                    <div class="">
                        <date-wrapper innerClass="font-size-xs" date={this.track.StartDt} format={"DD-MMM-YY HH:mm"} />
                        <span class="mx-1 font-size-xs">-</span>
                        <date-wrapper innerClass="font-size-xs" date={this.track.EndDt} format={"DD-MMM-YY HH:mm"} />
                    </div>
                </div>
            </div>
        );
    }
}
