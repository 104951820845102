import { Component, Vue, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import IconBase from "@/components/Icons/IconBase.vue";

@Component({
    components: {
        IconBase
    }
})
export default class BeaconOption extends Vue {
    @Prop()
    color!: string;

    @Prop()
    iconPath!: string;

    @Prop()
    name!: string;

    public render(): VNode {
        return (
            <div class="d-flex align-items-center">
                <div
                    style={{
                        backgroundColor: `${this.color}22`
                    }}
                    class="rounded-circle mr-2"
                >
                    <icon-base iconHeight={24} iconWidth={24} iconName={this.iconPath} iconColor={this.color} />
                </div>
                <div>{this.name}</div>
            </div>
        );
    }
}
