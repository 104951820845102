/* global ymaps */
import templates from "./templates";
import YandexMap from "@/components/Map/yandex/YandexMap";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import isNil from "lodash/isNil";

export default class PosititonMarker {
    private map: YandexMap;
    private beacon: IBeaconMonitoring;
    private layer: ymaps.Placemark;

    constructor(map: YandexMap, beacon: IBeaconMonitoring, clickHandler, bnwasManagement: boolean) {
        this.map = map;
        this.beacon = { ...beacon };
        this.layer = this.createPlacemark(this.beacon, bnwasManagement);
        this.layer.events.add("click", event => {
            if (clickHandler) {
                const target = (event as ymaps.IEvent).get("target");
                if (target) {
                    const placemark = target as ymaps.Placemark;
                    clickHandler(placemark.properties.getAll());
                }
            }
        });
    }

    public getBounds() {
        return this.layer.geometry.getBounds();
    }

    public focus() {
        const center = this.layer.geometry.getCoordinates();
        this.map.setCenter(center);
    }

    public show() {
        if (!this.map.isLayerOnMap(this.layer)) {
            this.map.addLayerToClusterer(this.layer);
        }
    }

    public hide() {
        this.map.removeLayerFromClusterer(this.layer);
    }

    private createLayout(template) {
        return ymaps.templateLayoutFactory.createClass(template);
    }

    private createPlacemark(beacon: IBeaconMonitoring, bnwasManagement: boolean) {
        const courseChannel = beacon.Channels.find(channel => channel.ChannelFlag === 4);

        let placemarkColor = "#1070ca";
        if (bnwasManagement) {
            if (beacon.BNWASShowStatus) {
                placemarkColor = beacon.BNWASAlarm ? "#ff4136" : "#0f9960";
            } else {
                placemarkColor = "#8897a2";
            }
        }

        const properties = {
            Id: beacon.Id,
            Name: beacon.Name,
            IconPath: beacon.IconPath,
            Course: courseChannel && courseChannel.Value ? courseChannel.Value : 0,
            needLabel: true,
            IconSize: 24,
            Color: placemarkColor
        };

        const template =
            courseChannel && !isNil(courseChannel.Value) ? templates.arrowMarkerTemplate : templates.markerTemplate;

        const options = {
            hasBalloon: false,
            iconLayout: "default#imageWithContent",
            iconContentLayout: this.createLayout(template),
            iconColor: placemarkColor,
            iconContentSize: [280, 32],
            iconImageHref: "",
            iconImageSize: [32, 32],
            iconImageOffset: [-32 / 2, -32 / 2]
        };

        return new ymaps.Placemark(beacon.Location.Coordinates, properties, options);
    }
}
