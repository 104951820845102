import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import { ChannelData } from "@/api/models/Timeline";

@Component
export default class ChannelsElement extends Vue {
    @Prop()
    channelsData!: ChannelData[];

    public render(): VNode | null {
        return this.channelsData.length > 0 ? (
            <table class="table" style="box-shadow: none;">
                <tbody>
                    {this.channelsData.map(data => (
                        <tr>
                            <td class="font-weight-medium" style="width: 250px;">
                                {data.Name}
                            </td>
                            <td>{data.FormattedValue}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : null;
    }
}
