export default [
    {
        // Без группировки
        value: 0,
        key: "nogrouping",
        rule: (beacons, groups, name?: string) => {
            let beaconIds = beacons.map(el => el.Id);
            return [{ Id: 0, Name: name, Key: "beacons", BeaconIds: beaconIds }];
        }
    },
    {
        // Личные группы
        value: 1,
        key: "personalgroups",
        rule: (beacons, groups) => groups
    }
];
