// import ymap from '@/components/Map/yandex'
import Geoobject from "@/components/Map/yandex/Geoobject";

export default {
    data() {
        return {
            geoobjects: []
        };
    },
    watch: {
        selectedGeoobjects(value) {
            this.showGeoobjects(value);
            this.hideGeoobjects(value);
        }
    },
    methods: {
        showGeoobjects(geoobjects) {
            let geoobjectsToFocus = [];
            for (const geoobject of geoobjects) {
                if (!this.geoobjects.some(el => el.Id === geoobject.Id)) {
                    let geoobjectData = {
                        Id: geoobject.Id,
                        Layer: new Geoobject(this.map, geoobject)
                    };
                    this.geoobjects.push(geoobjectData);
                    geoobjectsToFocus.push(geoobjectData.Layer);
                    geoobjectData.Layer.show();
                }
            }
            if (geoobjectsToFocus.length > 0) {
                this.map.focusToLayers(geoobjectsToFocus);
            }
        },
        hideGeoobjects(geoobjects) {
            this.geoobjects = this.geoobjects.filter(el => {
                if (geoobjects.some(geoobject => geoobject.Id === el.Id)) {
                    return true;
                } else {
                    el.Layer.hide();
                    return false;
                }
            });
        }
    }
};
