export default {
    markerTemplate: `
<div class="l-icon">
    <div class="l-icon_inner"
            style="background-color: {{ properties.Color }}; height: {{ properties.IconSize }}px; width: {{ properties.IconSize }}px; color: #fff;">
        {{ properties.Label }}
    </div>
    {% if (properties.needLabel) %}<div class="l-icon_label font-weight-medium">{{ properties.Name }}</div>{% endif %}
</div>`,
    arrowMarkerTemplate: `
<div class="l-icon__arrow">
    <div class="l-arrow-icon_inner__arrow"
            style="-webkit-transform: rotate({{properties.Course}}deg); transform: rotate({{properties.Course}}deg);">
      <svg
        viewBox="0 0 16 16"
        width="20"
        height="20"
        style="opacity:1;">
        <g stroke="#000" stroke-width="1">
          <path
            d="M1.47 13.81C0.93 14.97 1.44 15.64 2.6 15.31C2.97 15.21 4.81 13.86 8.12 11.27C11.32 13.85 13.1 15.19 13.45 15.3C14.62 15.63 15.13 14.96 14.6 13.8C14.05 12.58 9.63 2.85 9.08 1.63C8.55 0.46 7.68 0.46 7.14 1.62C7.14 1.62 2.04 12.59 1.47 13.81Z"
            fill="{{ properties.Color }}"
          ></path>
        </g>
      </svg>
    </div>
    {% if (properties.needLabel) %}<div class="l-icon_label__arrow">{{ properties.Name }}</div>{% endif %}
</div>`,
    trackArrowMarkerTemplate: `
<div class="l-icon__arrow_track">
    <div class="l-arrow-icon_inner__arrow"
            style="-webkit-transform: rotate({{properties.Course}}deg); transform: rotate({{properties.Course}}deg);">
      <svg
        viewBox="0 0 16 16"
        width="16"
        height="16"
        style="opacity:1;">
        <g stroke="#fff" stroke-width="1">
          <path
            d="M1.47 13.81C0.93 14.97 1.44 15.64 2.6 15.31C2.97 15.21 4.81 13.86 8.12 11.27C11.32 13.85 13.1 15.19 13.45 15.3C14.62 15.63 15.13 14.96 14.6 13.8C14.05 12.58 9.63 2.85 9.08 1.63C8.55 0.46 7.68 0.46 7.14 1.62C7.14 1.62 2.04 12.59 1.47 13.81Z"
            fill="{{ properties.Color }}"
          ></path>
        </g>
      </svg>
    </div>
    {% if (properties.needLabel) %}<div class="l-icon_label__arrow">{{ properties.Name }}</div>{% endif %}
</div>`
};
