import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import { StatusEventEnum, EventData } from "@/api/models/Timeline";

@Component
export default class EventElement extends Vue {
    @Prop()
    eventData!: EventData;

    public render(): VNode {
        return (
            <el-tag class="mr-1 mb-2" size="small">
                <span class="mr-1 font-weight-medium">Событие:</span>
                <span class="text-muted">{this.$t(`StatusEvent.${StatusEventEnum[this.eventData.Event]}`)}</span>
            </el-tag>
        );
    }
}
