import { Component, Vue, Mixins } from "vue-property-decorator";

import { updateSettings, loadSettings } from "@/api/account";
import dayjs from "dayjs";

import lengthUnits from "@/const/length-units";
import { localizations, cardSizes } from "@/const/enums";
import Messages from "@/components/Mixins/messages";
import timezoneOffsets from "@/const/timezone-offsets";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class UserSettings extends Mixins(Messages) {
    working: boolean = false;
    form: any = null;

    get user() {
        return this.$store.state.user || {};
    }

    updateLocaleSettings() {
        let localeKey = localizations[this.form.Localization].key;
        localStorage.setItem("locale", localeKey);
        this.$i18n.locale = localeKey;
        dayjs.locale(localeKey);
    }

    async saveSettings() {
        this.working = true;
        try {
            await updateSettings(this.form);
            this.updateLocaleSettings();
            this.operationSuccess();
        } finally {
            setTimeout(() => {
                this.working = false;
                location.reload();
            }, 1000);
        }
    }

    async loadSettings() {
        const settings = await loadSettings();
        if (settings) {
            this.form = cloneDeep(settings);
        }
    }

    mounted() {
        this.loadSettings();
    }

    render() {
        return this.form ? (
            <el-form disabled={!this.user.CanEditPersonalSettings} label-position="left">
                <el-form-item label={this.$t("profile.lengthUnits")}>
                    <el-select vModel={this.form.LengthUnit} size="small" placeholder={this.$t("profile.lengthUnits")}>
                        {lengthUnits.map(lengthUnit => (
                            <el-option
                                key={lengthUnit.value}
                                label={this.$t(`enums.lengthUnits.${lengthUnit.key}`)}
                                value={lengthUnit.value}
                            />
                        ))}
                    </el-select>
                </el-form-item>
                <el-form-item label={this.$t("profile.timezone")}>
                    <el-select
                        filterable
                        vModel={this.form.TimeZoneOffset}
                        size="small"
                        placeholder={this.$t("profile.timezone")}
                    >
                        {timezoneOffsets.map(timezoneOffset => (
                            <el-option
                                key={timezoneOffset.value}
                                label={this.$t(`enums.timezones.${timezoneOffset.key}`)}
                                value={timezoneOffset.value}
                            />
                        ))}
                    </el-select>
                </el-form-item>
                <el-form-item label={this.$t("profile.locale")}>
                    <el-select vModel={this.form.Localization} size="small" placeholder={this.$t("profile.locale")}>
                        {localizations.map(localization => (
                            <el-option key={localization.value} label={localization.label} value={localization.value} />
                        ))}
                    </el-select>
                </el-form-item>
                <el-form-item class="d-flex flex-column text-left" label={this.$t("profile.cardSize")}>
                    <el-radio-group vModel={this.form.MonitoringInterfaceSettings.CardSize} size="small">
                        {cardSizes.map(cardSize => (
                            <el-radio class="mr-2" key={cardSize.key} label={cardSize.key}>
                                {this.$t(`enums.cardSizes.${cardSize.key}`)}
                            </el-radio>
                        ))}
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-checkbox vModel={this.form.MonitoringInterfaceSettings.ShowPositionOnLoad}>
                        {this.$t("profile.showPositionOnLoad")}
                    </el-checkbox>
                </el-form-item>
                <el-form-item class="m-0 d-flex justify-content-center">
                    <el-button type="primary" size="small" loading={this.working} onClick={this.saveSettings}>
                        {this.$t("default.save")}
                    </el-button>
                </el-form-item>
            </el-form>
        ) : null;
    }
}
