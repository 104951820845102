import { Vue, Component } from "vue-property-decorator";

import TracksPanel from "@/components/Tracks/TracksPanel";
import TracksMap from "@/components/Tracks/TracksYMap";

@Component({
    name: "TracksView",
    components: {
        TracksPanel,
        TracksMap
    }
})
export default class TracksView extends Vue {
    render() {
        return (
            <div class="canvas">
                <div class="lo-panel">
                    <tracks-panel />
                </div>
                <tracks-map class="w-100 h-100" />
            </div>
        );
    }
}
