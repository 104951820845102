import { Component, Vue, Mixins } from "vue-property-decorator";
import Messages from "@/components/Mixins/messages";
import { changePassword } from "@/api/account";

@Component
export default class ChangePassword extends Mixins(Messages) {
    get user() {
        return this.$store.state.user || {};
    }

    working: boolean = false;
    changePassword: any = {
        Password: null,
        NewPassword: null,
        NewPasswordRepeat: null
    };

    checkPassword(rule, value, callback) {
        if (value === this.changePassword.NewPassword) {
            callback();
        } else {
            callback(new Error(this.$t("profile.passwordmustbeequal").toString()));
        }
    }

    async handleChangePassword() {
        try {
            let form: any = this.$refs["changePasswordForm"];
            await form.validate();

            this.changePassword.Password = this.changePassword.Password.trim();
            this.changePassword.NewPassword = this.changePassword.NewPassword.trim();
            this.changePassword.NewPasswordRepeat = this.changePassword.NewPasswordRepeat.trim();

            this.working = true;
            await changePassword(this.changePassword);
            this.changePassword = {
                Password: null,
                NewPassword: null,
                NewPasswordRepeat: null
            };
            form.resetFields();
            this.operationSuccess();
        } catch (error) {
            if (error.response && error.response.status === 404) {
                this.operationFailed();
            }
        } finally {
            this.working = false;
        }
    }

    render() {
        return (
            <el-form
                attrs={{ model: this.changePassword }}
                disabled={!this.user.CanEditPersonalSettings}
                ref="changePasswordForm"
            >
                <el-form-item
                    label={this.$t("profile.oldpassword")}
                    prop="Password"
                    rules={[
                        {
                            required: true,
                            message: this.$t("profile.oldpasswordvalidation")
                        }
                    ]}
                >
                    <el-input type="password" vModel={this.changePassword.Password} size="small" />
                </el-form-item>
                <el-form-item
                    label={this.$t("profile.newpassword")}
                    prop="NewPassword"
                    rules={[
                        {
                            required: true,
                            message: this.$t("profile.newpasswordvalidation")
                        },
                        {
                            min: 6,
                            max: 50,
                            message: this.$t("profile.passwordlength")
                        }
                    ]}
                >
                    <el-input type="password" vModel={this.changePassword.NewPassword} size="small" />
                </el-form-item>
                <el-form-item
                    label={this.$t("profile.repeatnewpassword")}
                    prop="NewPasswordRepeat"
                    rules={[
                        {
                            required: true,
                            message: this.$t("profile.newpasswordvalidation")
                        },
                        {
                            validator: this.checkPassword,
                            trigger: ["change", "blur"]
                        },
                        {
                            min: 6,
                            max: 50,
                            message: this.$t("profile.passwordlength")
                        }
                    ]}
                >
                    <el-input type="password" size="small" vModel={this.changePassword.NewPasswordRepeat} />
                </el-form-item>
                <el-form-item class="m-0 d-flex justify-content-center">
                    <el-button size="small" onClick={this.handleChangePassword} loading={this.working} type="primary">
                        {this.$t("profile.changepassword")}
                    </el-button>
                </el-form-item>
            </el-form>
        );
    }
}
