import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { VNode } from "vue";
import BeaconOption from "@/components/BeaconOption";
import IBeaconMonitoring from "@/contracts/IBeaconMonitoring";
import { State } from "vuex-class";
import getPeriods from "@/const/datepicker-options";
import "./HistoryPanel.scss";
import { ChannelFlag } from "@/contracts/IChannelInfo";
import IChannelMonitoring from "@/contracts/IChannelMonitoring";
import TimelinePanel from "@/components/History/TimelinePanel";
import TimelineElement from "@/api/models/Timeline";
import { PointClickEvent } from "./HistoryMap";

export interface HistoryForm {
    beaconId: number | null;
    datetimerange: any;
    sIds: number[];
}

@Component({
    components: {
        BeaconOption,
        TimelinePanel
    }
})
export default class HistoryPanel extends Vue {
    @Prop()
    loading!: boolean;

    @Prop()
    private timelineElements!: TimelineElement[];

    @Prop()
    private timestamp!: string;

    private onLocationClick(event: PointClickEvent) {
        this.$emit("locationClick", event);
    }

    @Watch("timelineElements")
    onTimelineElementsChanged() {
        if (this.timelineElements.length > 0) {
            this.view = "data";
        } else {
            this.view = "settings";
        }
    }

    @State("beacons", { namespace: "monitoring" })
    beacons!: IBeaconMonitoring[];

    view: "data" | "settings" = "settings";

    private onBackToSettings() {
        this.$emit("backToSettings");
    }

    private async onSubmitForm() {
        try {
            const form: any = this.$refs.form;
            await form.validate();
            this.$emit("submitForm", this.form, this.beacons.find(el => el.Id === this.form.beaconId));
        } catch (error) {}
    }

    private currentChannels: IChannelMonitoring[] = [];

    @Watch("form.beaconId")
    onBeaconIdChanged() {
        const currentBeacon = this.beacons.find(x => x.Id === this.form.beaconId);
        if (currentBeacon) {
            const filteredChannels = currentBeacon.Channels.filter(x => x.ChannelFlag !== ChannelFlag.Course);
            this.form.sIds = filteredChannels.map(x => x.Id);
            this.currentChannels = filteredChannels;
            this.toggleSelection();
        }
    }

    private form: HistoryForm = {
        beaconId: null,
        datetimerange: null,
        sIds: []
    };

    private pickerOptions: any = {};

    public mounted() {
        this.pickerOptions = getPeriods(this.$t);

        const start = new Date();
        const end = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
        this.form.datetimerange = [start, end];
    }

    private showPanel: boolean = true;
    private togglePanel() {
        this.showPanel = !this.showPanel;
        this.$emit("panelStateChanged", this.showPanel);
    }

    private handleSelectionChange(val: IChannelMonitoring[]) {
        this.form.sIds = val.map(val => val.Id);
    }

    private toggleSelection() {
        const table = this.$refs.table as any;
        table.clearSelection();
        this.currentChannels.forEach(channel => {
            table.toggleRowSelection(channel, true);
        });
    }

    private handleRowClick(row) {
        const table = this.$refs.table as any;
        table.toggleRowSelection(row);
    }

    get sideContainerStyle() {
        return !this.showPanel && "transform: translateX(-434px);";
    }

    public render(): VNode {
        return (
            <div class="HistoryPanel" style={this.sideContainerStyle}>
                <div class="d-flex flex-column w-100">
                    <div class="HistoryPanelMenu">
                        <div class="d-flex align-items-center pb-3">
                            {this.view === "data" && (
                                <el-button
                                    class="mr-3"
                                    onClick={this.onBackToSettings}
                                    size="mini"
                                    icon="fas fa-chevron-left"
                                />
                            )}
                            <h5 class="mb-0">
                                {this.view === "settings" ? this.$t("default.settings") : this.$t("default.timeline")}
                            </h5>
                        </div>
                    </div>
                    {this.view === "data" ? (
                        <timeline-panel
                            onLocationClick={this.onLocationClick}
                            timestamp={this.timestamp}
                            timelineElements={this.timelineElements}
                        ></timeline-panel>
                    ) : (
                        <div class="HistoryPanelForm">
                            <div class="font-weight-medium text-left w-100 mb-2">{this.$t("default.beacon")}</div>
                            <el-form attrs={{ model: this.form }} show-message={false} ref="form" size="small">
                                <el-form-item rules={[{ required: true }]} prop="beaconId">
                                    <el-select class="w-100" filterable vModel={this.form.beaconId}>
                                        {this.beacons.map(beacon => (
                                            <el-option key={beacon.Id} label={beacon.Name} value={beacon.Id}>
                                                <beacon-option
                                                    color={beacon.UserInterfaceSettings.Color}
                                                    iconPath={beacon.IconPath}
                                                    name={beacon.Name}
                                                />
                                            </el-option>
                                        ))}
                                    </el-select>
                                </el-form-item>
                                <div class="font-weight-medium text-left w-100 mb-2">{this.$t("default.interval")}</div>
                                <el-form-item rules={[{ required: true }]} prop="datetimerange">
                                    <el-date-picker
                                        class="w-100"
                                        vModel={this.form.datetimerange}
                                        clearable={false}
                                        size="small"
                                        format="yyyy-MM-dd HH:mm"
                                        type="datetimerange"
                                        picker-options={this.pickerOptions}
                                        range-separator="-"
                                        unlink-panels
                                        start-placeholder={this.$t("datepicker.from")}
                                        end-placeholder={this.$t("datepicker.to")}
                                        align="left"
                                    />
                                </el-form-item>
                                <div class="font-weight-medium text-left w-100 mb-2">{this.$t("default.sensors")}</div>
                                <el-form-item>
                                    <el-table
                                        data={this.currentChannels}
                                        empty-text={this.$t("default.nodata")}
                                        on-selection-change={this.handleSelectionChange}
                                        on-row-click={this.handleRowClick}
                                        height="300px"
                                        ref="table"
                                        row-key="Id"
                                        size="mini"
                                    >
                                        <el-table-column reserve-selection={true} type="selection" width="55" />
                                        <el-table-column label={this.$t("default.selectAll")} property="Name" />
                                    </el-table>
                                </el-form-item>
                            </el-form>
                            <el-button loading={this.loading} onClick={this.onSubmitForm} size="small" type="primary">
                                {this.$t("default.build")}
                            </el-button>
                        </div>
                    )}
                </div>
                <div class="HistoryPanelToggler">
                    <el-button
                        onClick={this.togglePanel}
                        type="text"
                        class="w-100"
                        icon={this.showPanel ? "fas fa-chevron-left" : "fas fa-chevron-right"}
                    />
                </div>
            </div>
        );
    }
}
