import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import "./SideContainer.scss";

@Component
export default class SideContainer extends Vue {
    @Prop()
    sidebarOpen!: boolean;

    get sideContainerStyle() {
        const style = {
            transform: !this.showPanel ? "translateY(calc(50vh + 8px))" : "translateY(0)"
        };
        if (this.sidebarOpen) {
            return {
                width: "calc(100% - 450px)",
                ...style
            };
        } else {
            return {
                width: "calc(100% - 16px)",
                ...style
            };
        }
    }

    private toggleContainer() {
        this.showPanel = !this.showPanel;
    }

    private showPanel: boolean = true;
    public render(): VNode {
        return (
            <div class="SideContainer" style={this.sideContainerStyle}>
                <div class="SideToggler">
                    <el-button
                        onClick={this.toggleContainer}
                        type="text"
                        class="SideTogglerButton"
                        icon={this.showPanel ? "fas fa-chevron-down" : "fas fa-chevron-up"}
                    />
                </div>
                {this.$slots.default}
            </div>
        );
    }
}
