import YandexMap from "@/components/Map/yandex/YandexMap";

export default class HistoryMarker {
    public map: YandexMap;
    public layer: ymaps.Placemark;

    constructor(map, coordinates, color) {
        this.map = map;

        this.layer = this.createPlacemark(coordinates, color);
    }

    public getBounds(): ymaps.CoordinateBounds | null {
        return this.layer.geometry.getBounds();
    }

    public focus(): void {
        const center = this.layer.geometry.getCoordinates();
        if (center) {
            this.map.setCenter(center);
        }
    }

    public focusAndZoom(): void {
        const center = this.layer.geometry.getCoordinates();
        if (center) {
            this.map.setCenter(center);
            if (this.map.getZoom() < 12) {
                this.map.setZoom(12);
            }
        }
    }

    public show() {
        if (!this.map.isLayerOnMap(this.layer)) {
            this.map.addLayerToClusterer(this.layer);
        }
    }

    public hide() {
        this.map.removeLayerFromClusterer(this.layer);
    }

    private createPlacemark(coordinates, color) {
        return new ymaps.Placemark(coordinates, undefined, {
            preset: "islands#dotIcon",
            iconColor: color,
            iconImageSize: [32, 32],
            iconImageOffset: [-32 / 2, -32 / 2]
        });
    }
}
