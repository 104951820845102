const SET_BOUNDS_DEFAULT_OPTIONS = {
    checkZoomRange: true,
    zoomMargin: 20
};

export default class YandexMap {
    private mapLayer: ymaps.Map;
    private clusterer: ymaps.Clusterer;

    constructor(element, options) {
        this.mapLayer = new ymaps.Map(element, {
            controls: ["rulerControl"],
            ...options
        });

        const typeSelector = this.createTypeSelectorControl();
        this.mapLayer.controls.add(typeSelector);

        const zoomControl = this.createZoomControl();
        this.mapLayer.controls.add(zoomControl);

        this.clusterer = new ymaps.Clusterer({
            hasBalloon: false,
            clusterIconLayout: "default#pieChart",
            clusterIconPieChartRadius: 14,
            // Радиус центральной части макета.
            clusterIconPieChartCoreRadius: 10,
            // Ширина линий-разделителей секторов и внешней обводки диаграммы.
            clusterIconPieChartStrokeWidth: 0,
            maxZoom: 18
        });
        this.mapLayer.geoObjects.add(this.clusterer);

        const mapType = localStorage.getItem("mapType");
        if (mapType) {
            this.mapLayer.setType(mapType);
        }
        this.mapLayer.events.add("typechange", () => {
            localStorage.setItem("mapType", this.mapLayer.getType().toString());
        });
    }

    public addLayerToClusterer(positionMarker) {
        this.clusterer.add(positionMarker);
    }

    public removeLayerFromClusterer(positionMarker) {
        this.clusterer.remove(positionMarker);
    }

    public addLayer(track) {
        this.mapLayer.geoObjects.add(track);
    }

    public removeLayer(track) {
        this.mapLayer.geoObjects.remove(track);
    }

    public addEvent(event, callback) {
        this.mapLayer.events.add(event, callback);
    }

    public removeEvent(event, callback) {
        this.mapLayer.events.remove(event, callback);
    }

    public isLayerOnMap(layer) {
        return this.mapLayer.geoObjects.indexOf(layer) > -1;
    }

    public setBounds(bounds) {
        this.mapLayer.setBounds(bounds, SET_BOUNDS_DEFAULT_OPTIONS);
    }

    public setCenter(center) {
        this.mapLayer.setCenter(center);
    }

    public setZoom(zoom: number = 12) {
        this.mapLayer.setZoom(zoom);
    }

    public getZoom() {
        return this.mapLayer.getZoom();
    }

    public getBounds() {
        return this.mapLayer.getBounds();
    }

    public getCenter() {
        return this.mapLayer.getCenter();
    }

    public focusToLayers(layers) {
        const layersBounds: ymaps.CoordinateBounds[] = [];
        for (const layer of layers) {
            const layerBounds = layer.getBounds();
            layersBounds.push(layerBounds);
        }
        const bounds = ymaps.util.bounds.fromBounds(layersBounds);
        this.setBounds(bounds);
    }

    public fitToViewport() {
        this.mapLayer.container.fitToViewport();
    }

    private createTypeSelectorControl() {
        this.addOsmMapType();
        // this.addOSeaMapType();
        this.addYandexMapType();
        return new ymaps.control.TypeSelector({
            mapTypes: ["yandex#map", "osm#map"],
            options: {
                panoramasItemMode: "off"
            }
        });
    }

    private addYandexMapType() {
        const osmMapType = new ymaps.MapType("Yandex", ["yandex#map"]);
        ymaps.mapType.storage.add("yandex#map", osmMapType);
    }

    private addOsmMapType() {
        const OSMLayer = () => {
            const layer = new ymaps.Layer("http://tile.openstreetmap.org/%z/%x/%y.png", {
                projection: ymaps.projection.sphericalMercator
            });
            layer.getCopyrights = () => {
                return ymaps.vow.resolve("OpenStreetMap contributors, CC-BY-SA");
            };
            layer.getZoomRange = () => {
                return ymaps.vow.resolve<[number, number]>([0, 18]);
            };

            return layer;
        };
        ymaps.layer.storage.add("osm#map", OSMLayer);
        const osmMapType = new ymaps.MapType("OpenStreetMap", ["osm#map"]);
        ymaps.mapType.storage.add("osm#map", osmMapType);
    }

    // private addOSeaMapType() {
    //     const OSeaLayer = () => {
    //         const layer = new ymaps.Layer("http://t1.openseamap.org/seamark/%z/%x/%y.png", {
    //             projection: ymaps.projection.sphericalMercator
    //         });
    //         layer.getCopyrights = () => {
    //             return ymaps.vow.resolve(" OpenStreetMap contributors, CC-BY-SA");
    //         };
    //         layer.getZoomRange = () => {
    //             return ymaps.vow.resolve<[number, number]>([0, 18]);
    //         };

    //         return layer;
    //     };
    //     ymaps.layer.storage.add("os#map", OSeaLayer);
    //     const osMapType = new ymaps.MapType("OpenSeaMap", ["osm#map", "os#map"]);
    //     ymaps.mapType.storage.add("os#map", osMapType);
    // }

    private createZoomControl() {
        return new ymaps.control.ZoomControl({
            options: {
                position: {
                    top: 60,
                    right: 10
                }
            }
        });
    }
}
